export const Types = {
  // GET INTERSECTION
  GET_INTERSECTION_REQUEST: 'GET_INTERSECTION_REQUEST',
  GET_INTERSECTION_SUCCESS: 'GET_INTERSECTION_SUCCESS',
  GET_INTERSECTION_FAIL: 'GET_INTERSECTION_FAIL',
  GET_INTERSECTION_CLEAN: 'GET_INTERSECTION_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
};

export default function intersection(state = INITIAL_STATE, action) {
  switch (action.type) {
    // GET INTERSECTION
    case Types.GET_INTERSECTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_INTERSECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_INTERSECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Types.GET_INTERSECTION_CLEAN:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET INTERSECTION
  getIntersectionRequest: payload => ({
    type: Types.GET_INTERSECTION_REQUEST,
    payload,
  }),
  getIntersectionSuccess: payload => ({
    type: Types.GET_INTERSECTION_SUCCESS,
    payload,
  }),
  getIntersectionFail: payload => ({
    type: Types.GET_INTERSECTION_FAIL,
    payload,
  }),
  getIntersectionClean: () => ({
    type: Types.GET_INTERSECTION_CLEAN,
  }),
};

import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { Types as MoldTypes, Creators as MoldActions } from 'store/ducks/Mold'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getMold({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/molds', payload)
    if (status === 200) {
      yield put(MoldActions.getMoldSuccess(data))
    } else {
      parseError(data || 'Erro ao listar gráfico.')
      yield put(MoldActions.getMoldFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao listar gráfico.')
    yield put(MoldActions.getMoldFail())
  }
}

function* postMold({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/molds', payload)
    if (status === 200) {
      NotificationManager.success('Gráfico criado com sucesso.')
      yield put(MoldActions.postMoldSuccess())
    } else {
      parseError(data || 'Erro ao criar gráfico')
      yield put(MoldActions.postMoldFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao criar gráfico')
    yield put(MoldActions.postMoldFail())
  }
}

function* showMold({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/molds/${payload}?with=variables`,
    )
    if (status === 200) {
      yield put(MoldActions.showMoldSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar gráfico')
      yield put(MoldActions.showMoldFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar gráfico')
    yield put(MoldActions.showMoldFail())
  }
}

function* updateMold({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/molds/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Gráfico editado com sucesso.')
      yield put(MoldActions.updateMoldSuccess())
    } else {
      parseError(data || 'Erro ao editar gráfico')
      yield put(MoldActions.updateMoldFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar gráfico')
    yield put(MoldActions.updateMoldFail())
  }
}

function* deleteMold({ payload }) {
  try {
    const { dataAll: charts } = yield select(state => state.mold)
    const { data, status } = yield call(api.delete, `/molds/${payload}`)
    if (status === 200 || status === 204) {
      const index = charts?.findIndex(item => item.id === payload)
      if (index !== -1) {
        charts.splice(index, 1)
      }

      yield put(MoldActions.getAllMoldsSuccess(charts))
      yield put(MoldActions.deleteMoldSuccess())
      NotificationManager.success('Gráfico deletado com sucesso.')
    } else {
      parseError(data || 'Erro ao delete o gráfico')
      yield put(MoldActions.deleteMoldFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao delete o gráfico')
    yield put(MoldActions.deleteMoldFail())
  }
}

function* addVariable({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/molds/addVariable/${payload.id}`,
      payload.data,
    )
    if (status === 201) {
      NotificationManager.success('Variável adicionado com sucesso.')
      yield put(MoldActions.addVariableSuccess())
    } else if (status === 200) {
      NotificationManager.success('Variável já cadastrado.')
      yield put(MoldActions.addVariableSuccess())
    } else {
      parseError(data || 'Erro ao adicionar a variável')
      yield put(MoldActions.addVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao adicionar a variável')
    yield put(MoldActions.addVariableFail())
  }
}

function* deleteVariable({ payload }) {
  try {
    const { data, status } = yield call(
      api.delete,
      `/molds/removeVariable/${payload.id}`,
      { params: payload.data },
    )
    if (status === 200) {
      NotificationManager.success('Variável deletado com sucesso.')
      yield put(MoldActions.deleteVariableSuccess())
    } else {
      parseError(data || 'Erro ao deletar a variável')
      yield put(MoldActions.deleteVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao deletar a variável')
    yield put(MoldActions.deleteVariableFail())
  }
}

function* updateVariable({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/molds/updateVariable/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Variável editada com sucesso.')
      yield put(MoldActions.updateVariableSuccess())
    } else {
      parseError(data || 'Erro ao editar a variável')
      yield put(MoldActions.updateVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar a variável')
    yield put(MoldActions.updateVariableFail())
  }
}

function* getAllMolds({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/molds', payload)
    if (status === 200) {
      yield put(MoldActions.getAllMoldsSuccess(data))
    } else {
      parseError(data || 'Erro ao listar gráficos')
      yield put(MoldActions.getAllMoldsFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao listar gráficos')
    yield put(MoldActions.getAllMoldsFail())
  }
}

function* getMoldWatcher() {
  yield takeLatest(MoldTypes.GET_MOLD_REQUEST, getMold)
}

function* postMoldWatcher() {
  yield takeLatest(MoldTypes.POST_MOLD_REQUEST, postMold)
}

function* showMoldWatcher() {
  yield takeLatest(MoldTypes.SHOW_MOLD_REQUEST, showMold)
}

function* updateMoldWatcher() {
  yield takeLatest(MoldTypes.UPDATE_MOLD_REQUEST, updateMold)
}

function* deleteMoldWatcher() {
  yield takeLatest(MoldTypes.DELETE_MOLD_REQUEST, deleteMold)
}

function* addVariableWatcher() {
  yield takeLatest(MoldTypes.ADD_VARIABLE_MOLD_REQUEST, addVariable)
}

function* deleteVariableWatcher() {
  yield takeLatest(MoldTypes.DELETE_VARIABLE_MOLD_REQUEST, deleteVariable)
}

function* updateVariableWatcher() {
  yield takeLatest(MoldTypes.UPDATE_VARIABLE_MOLD_REQUEST, updateVariable)
}

function* getAllMoldsWatcher() {
  yield takeLatest(MoldTypes.GET_ALL_MOLDS_REQUEST, getAllMolds)
}

export default function* rootSaga() {
  yield all([
    fork(getMoldWatcher),
    fork(postMoldWatcher),
    fork(showMoldWatcher),
    fork(updateMoldWatcher),
    fork(deleteMoldWatcher),
    fork(addVariableWatcher),
    fork(deleteVariableWatcher),
    fork(updateVariableWatcher),
    fork(getAllMoldsWatcher),
  ])
}

import { NotificationManager } from 'react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Types as AlarmDataTypes,
  Creators as AlarmDataActions,
} from 'store/ducks/AlarmData';
import api from 'util/Api';
import { parseError } from 'util/index';

function* getAlarmDataDashboard({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/alarmData/dashboard', payload);
    if (status === 200) {
      yield put(AlarmDataActions.getAlarmDataSuccess(data));
    } else {
      parseError(data || 'Erro ae atualizar o alarme.');
      yield put(AlarmDataActions.getAlarmDataFail());
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o alarme.');
    yield put(AlarmDataActions.getAlarmDataFail());
  }
}

function* getAlarmDataMonitoring({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/alarmData/monitoring', payload);
    if (status === 200) {
      yield put(AlarmDataActions.getAlarmDataSuccess(data));
    } else {
      parseError(data || 'Erro ae atualizar o alarme.');
      yield put(AlarmDataActions.getAlarmDataFail());
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o alarme.');
    yield put(AlarmDataActions.getAlarmDataFail());
  }
}

function* getAlarmDataHistory({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/alarmData/history', payload);
    if (status === 200) {
      yield put(AlarmDataActions.getAlarmDataSuccess(data));
    } else {
      parseError(data || 'Erro ae atualizar o alarme.');
      yield put(AlarmDataActions.getAlarmDataFail());
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o alarme.');
    yield put(AlarmDataActions.getAlarmDataFail());
  }
}

function* getAlarmDataWatcher() {
  yield takeLatest(AlarmDataTypes.GET_ALARM_DATA_DASHBOARD_REQUEST, getAlarmDataDashboard);
  yield takeLatest(AlarmDataTypes.GET_ALARM_DATA_MONITORING_REQUEST, getAlarmDataMonitoring);
  yield takeLatest(AlarmDataTypes.GET_ALARM_DATA_HISTORY_REQUEST, getAlarmDataHistory);
}

export default function* rootSaga() {
  yield all([fork(getAlarmDataWatcher)]);
}

import { NotificationManager } from 'react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Types as StatusTypes,
  Creators as StatusActions,
} from 'store/ducks/Statuses';
import api from 'util/Api';
import { parseError } from 'util/index';

function* getStatus({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/status', payload);
    if (status === 200) {
      yield put(StatusActions.getStatusSuccess(data));
    } else {
      parseError(data || 'Erro ao listar status.');
      yield put(StatusActions.getStatusFail());
    }
  } catch (err) {
    parseError(err || 'Erro ao listar status.');
    yield put(StatusActions.getStatusFail());
  }
}

function* postStatus({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/status', payload);
    if (status === 200) {
      NotificationManager.success('Status criado com sucesso.');
      yield put(StatusActions.postStatusSuccess());
    } else {
      parseError(data || 'Erro ao criar status.');
      yield put(StatusActions.postStatusFail());
    }
  } catch (err) {
    parseError(err || 'Erro ao criar status.');
    yield put(StatusActions.postStatusFail());
  }
}

function* getStatusWatcher() {
  yield takeLatest(StatusTypes.GET_STATUS_REQUEST, getStatus);
}

function* postStatusWatcher() {
  yield takeLatest(StatusTypes.POST_STATUS_REQUEST, postStatus);
}

export default function* rootSaga() {
  yield all([fork(getStatusWatcher), fork(postStatusWatcher)]);
}

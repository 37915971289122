import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects'
import Api from 'util/Api'
import { parseError } from 'util/index'
import {
  Types as TemplateTypes,
  Creators as TemplateActions,
} from '../ducks/Template'

function* createTemplateSagas({ payload }) {
  try {
    const { status, data } = yield call(Api.post, '/templates', payload)
    if (status === 200) {
      NotificationManager.success('Template cadastrado com sucesso.')
      yield put(TemplateActions.createTemplateSuccess(data))
    } else {
      parseError(data || 'Falha ao cadastrar template.')
      yield put(TemplateActions.createTemplateError())
    }
  } catch (err) {
    parseError(err || 'Falha ao cadastrar template.')
    yield put(TemplateActions.createTemplateError())
  }
}

function* listTemplateSagas({ payload }) {
  try {
    const { status, data } = yield call(Api.get, '/templates', payload)
    if (status === 200) {
      yield put(TemplateActions.listTemplateSuccess(data))
    } else {
      parseError(data || 'Falha ao listar templates.')
      yield put(TemplateActions.listTemplateError())
    }
  } catch (err) {
    parseError(err || 'Falha ao listar templates.')
    yield put(TemplateActions.listTemplateError())
  }
}

function* getTemplateIdSagas({ payload }) {
  try {
    const { status, data } = yield call(Api.get, `/templates/${payload}`)
    if (status === 200) {
      yield put(TemplateActions.getTemplateIdSuccess(data))
    } else {
      parseError(data || 'Falha ao carregar templates.')
      yield put(TemplateActions.getTemplateIdError())
    }
  } catch (err) {
    parseError(err || 'Falha ao carregar templates.')
    yield put(TemplateActions.getTemplateIdError())
  }
}

function* editTemplateSagas({ payload }) {
  const { id, params } = payload
  try {
    const { status, data } = yield call(Api.put, `/templates/${id}`, params)
    if (status === 200) {
      NotificationManager.success('Template editado com sucesso.')
      yield put(TemplateActions.editTemplateSuccess(data))
    } else {
      parseError(data || 'Erro ao editar template.')
      yield put(TemplateActions.editTemplateError())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar template.')
    yield put(TemplateActions.editTemplateError())
  }
}

function* deleteTemplateSagas({ payload }) {
  try {
    const { data: templates } = yield select(state => state.template)
    const { status } = yield call(Api.delete, `/templates/${payload}`)
    if (status === 200 || status === 204) {
      const index = templates?.findIndex(item => item.id === payload)
      if (index !== -1) {
        templates.splice(index, 1)
      }

      yield put(TemplateActions.listTemplateSuccess(templates))
      yield put(TemplateActions.deleteTemplateSuccess())
      NotificationManager.success('Template deletado com sucesso.')
    } else {
      parseError(templates || 'Erro ao deletar template.')
      yield put(TemplateActions.deleteTemplateError())
    }
  } catch (err) {
    parseError(err || 'Erro ao deletar template.')
    yield put(TemplateActions.deleteTemplateError())
  }
}

function* importVariable({ payload }) {
  try {
    const { data, status } = yield call(
      Api.post,
      `/templates/import/${payload.id}`,
      payload.data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    )
    if (status === 200) {
      NotificationManager.success('Variável importada com sucesso.')
      yield put(TemplateActions.importVariableTemplateSuccess())
    } else {
      parseError(data || 'Erro ao importar variável.')
      yield put(TemplateActions.importVariableTemplateFail(data))
    }
  } catch (err) {
    parseError(err || 'Erro ao importar variável.')
    yield put(TemplateActions.importVariableTemplateFail(err?.response?.data))
  }
}

function* getHasIcon({ payload }) {
  try {
    const { data, status } = yield call(
      Api.get,
      `/templates/hasIcon/${payload}`,
    )
    if (status === 200) {
      yield put(TemplateActions.getHasIconTemplateSuccess(data))
    } else {
      parseError(data || 'Erro ao listar os icones.')
      yield put(TemplateActions.getHasIconTemplateFail(data))
    }
  } catch (err) {
    parseError(err || 'Erro ao listar os icones.')
    yield put(TemplateActions.getHasIconTemplateFail(err?.response?.data))
  }
}

function* watchCreateTemplate() {
  yield takeLatest(TemplateTypes.CREATE_TEMPLATE_REQUEST, createTemplateSagas)
}

function* watchListTemplate() {
  yield takeLatest(TemplateTypes.LIST_TEMPLATES_REQUEST, listTemplateSagas)
}

function* watchGetTemplateId() {
  yield takeLatest(TemplateTypes.GET_TEMPLATE_ID_REQUEST, getTemplateIdSagas)
}

function* watchEditTemplate() {
  yield takeLatest(TemplateTypes.EDIT_TEMPLATE_REQUEST, editTemplateSagas)
}

function* watchDeleteTemplate() {
  yield takeLatest(TemplateTypes.DELETE_TEMPLATE_REQUEST, deleteTemplateSagas)
}

function* importVariableWatcher() {
  yield takeLatest(
    TemplateTypes.IMPORT_VARIABLE_TEMPLATE_REQUEST,
    importVariable,
  )
}

function* getHasIconWatcher() {
  yield takeLatest(TemplateTypes.GET_HAS_ICON_TEMPLATE_REQUEST, getHasIcon)
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateTemplate),
    fork(watchListTemplate),
    fork(watchEditTemplate),
    fork(watchDeleteTemplate),
    fork(watchGetTemplateId),
    fork(importVariableWatcher),
    fork(getHasIconWatcher),
  ])
}

export const Types = {
  LIST_TEMPLATES_REQUEST: 'LIST_TEMPLATES_REQUEST',
  LIST_TEMPLATES_SUCCESS: 'LIST_TEMPLATES_SUCCESS',
  LIST_TEMPLATES_ERROR: 'LIST_TEMPLATES_ERROR',

  GET_TEMPLATE_ID_REQUEST: 'GET_TEMPLATE_ID_REQUEST',
  GET_TEMPLATE_ID_SUCCESS: 'GET_TEMPLATE_ID_SUCCESS',
  GET_TEMPLATE_ID_ERROR: 'GET_TEMPLATE_ID_ERROR',
  GET_TEMPLATE_ID_CLEAN: 'GET_TEMPLATE_ID_CLEAN',

  CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_ERROR: 'CREATE_TEMPLATE_ERROR',
  CREATE_TEMPLATE_CLEAN: 'CREATE_TEMPLATE_CLEAN',

  EDIT_TEMPLATE_REQUEST: 'EDIT_TEMPLATE_REQUEST',
  EDIT_TEMPLATE_SUCCESS: 'EDIT_TEMPLATE_SUCCESS',
  EDIT_TEMPLATE_ERROR: 'EDIT_TEMPLATE_ERROR',
  EDIT_TEMPLATE_CLEAN: 'EDIT_TEMPLATE_CLEAN',

  DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_ERROR: 'DELETE_TEMPLATE_ERROR',
  DELETE_TEMPLATE_CLEAN: 'DELETE_TEMPLATE_CLEAN',

  IMPORT_VARIABLE_TEMPLATE_REQUEST: 'IMPORT_VARIABLE_TEMPLATE_REQUEST',
  IMPORT_VARIABLE_TEMPLATE_SUCCESS: 'IMPORT_VARIABLE_TEMPLATE_SUCCESS',
  IMPORT_VARIABLE_TEMPLATE_FAIL: 'IMPORT_VARIABLE_TEMPLATE_FAIL',
  IMPORT_VARIABLE_TEMPLATE_CLEAN: 'IMPORT_VARIABLE_TEMPLATE_CLEAN',

  GET_HAS_ICON_TEMPLATE_REQUEST: 'GET_HAS_ICON_TEMPLATE_REQUEST',
  GET_HAS_ICON_TEMPLATE_SUCCESS: 'GET_HAS_ICON_TEMPLATE_SUCCESS',
  GET_HAS_ICON_TEMPLATE_FAIL: 'GET_HAS_ICON_TEMPLATE_FAIL',
  GET_HAS_ICON_TEMPLATE_CLEAN: 'GET_HAS_ICON_TEMPLATE_CLEAN',
};

const INIT_STATE = {
  data: null,
  loading: false,
  success: false,
  error: false,

  createLoading: false,
  createSuccess: false,
  createError: false,

  dataId: null,
  loadingId: false,
  successId: false,
  errorId: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,

  importLoading: false,
  importSuccess: false,
  importError: false,
  importMessages: null,

  hasIconLoading: false,
  hasIconError: false,
  hasIconData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_TEMPLATE_REQUEST:
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createError: false,
      };
    case Types.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        createData: action.payload,
        createLoading: false,
        createSuccess: true,
        createError: false,
      };
    case Types.CREATE_TEMPLATE_ERROR:
      return {
        ...state,
        createLoading: false,
        createSuccess: false,
        createError: true,
      };
    case Types.CREATE_TEMPLATE_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.LIST_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case Types.LIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
      };
    case Types.LIST_TEMPLATES_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case Types.GET_TEMPLATE_ID_REQUEST:
      return {
        ...state,
        loadingId: true,
        successId: false,
        errorIdId: false,
      };
    case Types.GET_TEMPLATE_ID_SUCCESS:
      return {
        ...state,
        dataId: action.payload,
        loadingId: false,
        successId: true,
        errorId: false,
      };
    case Types.GET_TEMPLATE_ID_ERROR:
      return {
        ...state,
        loadingId: false,
        successId: false,
        errorId: true,
      };
    case Types.GET_TEMPLATE_ID_CLEAN:
      return {
        ...state,
        dataId: null,
        loadingId: false,
        successId: false,
        errorId: false,
      };

    case Types.EDIT_TEMPLATE_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateError: false,
      };
    case Types.EDIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateError: false,
      };
    case Types.EDIT_TEMPLATE_ERROR:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        updateError: true,
      };
    case Types.EDIT_TEMPLATE_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        updateError: false,
      };

    case Types.DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      };
    case Types.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      };
    case Types.DELETE_TEMPLATE_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      };
    case Types.DELETE_TEMPLATE_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      };

    case Types.IMPORT_VARIABLE_TEMPLATE_REQUEST:
      return {
        ...state,
        importLoading: true,
        importSuccess: false,
        importError: false,
      };
    case Types.IMPORT_VARIABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        importLoading: false,
        importSuccess: true,
        importError: false,
      };
    case Types.IMPORT_VARIABLE_TEMPLATE_FAIL:
      return {
        ...state,
        importLoading: false,
        importSuccess: false,
        importError: true,
        importMessages: action.payload,
      };
    case Types.IMPORT_VARIABLE_TEMPLATE_CLEAN:
      return {
        ...state,
        importLoading: false,
        importSuccess: false,
        importError: false,
        importMessages: null,
      };

      // HAS ICON
    case Types.GET_HAS_ICON_TEMPLATE_REQUEST:
      return {
        ...state,
        hasIconLoading: true,
        hasIconError: false
      };
    case Types.GET_HAS_ICON_TEMPLATE_SUCCESS:
      return {
        ...state,
        hasIconLoading: false,
        hasIconError: false,
        hasIconData: action.payload
      };
    case Types.GET_HAS_ICON_TEMPLATE_FAIL:
      return {
        ...state,
        hasIconLoading: false,
        hasIconError: true,
      };
    case Types.GET_HAS_ICON_TEMPLATE_CLEAN:
      return {
        ...state,
        hasIconLoading: false,
        hasIconError: false,
        hasIconData: null
      };

    default:
      return state;
  }
};

export const Creators = {
  // CREATE
  createTemplateRequest: (payload) => ({
    type: Types.CREATE_TEMPLATE_REQUEST,
    payload,
  }),
  createTemplateSuccess: (payload) => ({
    type: Types.CREATE_TEMPLATE_SUCCESS,
    payload,
  }),
  createTemplateError: () => ({
    type: Types.CREATE_TEMPLATE_ERROR,
  }),
  createTemplateReset: () => ({
    type: Types.CREATE_TEMPLATE_CLEAN,
  }),

  // LIST
  listTemplateRequest: (payload) => ({
    type: Types.LIST_TEMPLATES_REQUEST,
    payload,
  }),
  listTemplateSuccess: (payload) => ({
    type: Types.LIST_TEMPLATES_SUCCESS,
    payload,
  }),
  listTemplateError: () => ({
    type: Types.LIST_TEMPLATES_ERROR,
  }),

  // SHOW
  getTemplateIdRequest: (payload) => ({
    type: Types.GET_TEMPLATE_ID_REQUEST,
    payload,
  }),
  getTemplateIdSuccess: (payload) => ({
    type: Types.GET_TEMPLATE_ID_SUCCESS,
    payload,
  }),
  getTemplateIdError: () => ({
    type: Types.GET_TEMPLATE_ID_ERROR,
  }),
  getTemplateIdClean: () => ({
    type: Types.GET_TEMPLATE_ID_CLEAN,
  }),

  // UPDATE
  editTemplateRequest: (payload) => ({
    type: Types.EDIT_TEMPLATE_REQUEST,
    payload,
  }),
  editTemplateSuccess: () => ({
    type: Types.EDIT_TEMPLATE_SUCCESS,
  }),
  editTemplateError: () => ({
    type: Types.EDIT_TEMPLATE_ERROR,
  }),
  editTemplateClean: () => ({
    type: Types.EDIT_TEMPLATE_CLEAN,
  }),

  // DELETE
  deleteTemplateRequest: (payload) => ({
    type: Types.DELETE_TEMPLATE_REQUEST,
    payload,
  }),
  deleteTemplateSuccess: () => ({
    type: Types.DELETE_TEMPLATE_SUCCESS,
  }),
  deleteTemplateError: () => ({
    type: Types.DELETE_TEMPLATE_ERROR,
  }),
  deleteTemplateClean: () => ({
    type: Types.DELETE_TEMPLATE_CLEAN,
  }),

  // IMPORT VARIABLES
  importVariableTemplateRequest: (payload) => ({
    type: Types.IMPORT_VARIABLE_TEMPLATE_REQUEST,
    payload,
  }),
  importVariableTemplateSuccess: (payload) => ({
    type: Types.IMPORT_VARIABLE_TEMPLATE_SUCCESS,
    payload,
  }),
  importVariableTemplateFail: (payload) => ({
    type: Types.IMPORT_VARIABLE_TEMPLATE_FAIL,
    payload,
  }),
  importVariableTemplateClean: () => ({
    type: Types.IMPORT_VARIABLE_TEMPLATE_CLEAN,
  }),

  // HAS ICON
  getHasIconTemplateRequest: (payload) => ({
    type: Types.GET_HAS_ICON_TEMPLATE_REQUEST,
    payload
  }),
  getHasIconTemplateSuccess: (payload) => ({
    type: Types.GET_HAS_ICON_TEMPLATE_SUCCESS,
    payload
  }),
  getHasIconTemplateFail: (payload) => ({
    type: Types.GET_HAS_ICON_TEMPLATE_FAIL,
    payload
  }),
  getHasIconTemplateClean: () => ({
    type: Types.GET_HAS_ICON_TEMPLATE_CLEAN
  })
};

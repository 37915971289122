import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  Types as VariableDataTypes,
  Creators as VariableDataActions,
} from 'store/ducks/VariableData'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getMonitoring({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/variableData/monitoring',
      payload,
    )
    if (status === 200) {
      yield put(VariableDataActions.getMonitoringSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o monitoramento.')
      yield put(VariableDataActions.getMonitoringFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o monitoramento.')
    yield put(VariableDataActions.getMonitoringFail())
  }
}

function* getAlarmMonitoring({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/variableData/alarm-monitoring',
      payload,
    )
    if (status === 200) {
      yield put(VariableDataActions.getAlarmMonitoringSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o monitoramento de alarmes.')
      yield put(VariableDataActions.getAlarmMonitoringFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o monitoramento de alarmes.')
    yield put(VariableDataActions.getAlarmMonitoringFail())
  }
}

function* getDetourMonitoring({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/variableData/detour-monitoring',
      payload,
    )
    if (status === 200) {
      yield put(VariableDataActions.getDetourMonitoringSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o monitoramento de desvios.')
      yield put(VariableDataActions.getDetourMonitoringFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o monitoramento de desvios.')
    yield put(VariableDataActions.getDetourMonitoringFail())
  }
}

function* getInsightMonitoring({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/variableData/insights',
      payload,
    )
    if (status === 200) {
      yield put(VariableDataActions.getInsightMonitoringSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o monitoramento de insights.')
      yield put(VariableDataActions.getInsightMonitoringFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o monitoramento de insights.')
    yield put(VariableDataActions.getInsightMonitoringFail())
  }
}

function* getPlotting({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/variableData/plot', payload)
    if (status === 200) {
      yield put(VariableDataActions.getPlottingSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar a plotagem.')
      yield put(VariableDataActions.getPlottingFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar a plotagem.')
    yield put(VariableDataActions.getPlottingFail())
  }
}

function* getMonitoringDashboard({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/variableData/monitoringDashboard',
      payload,
    )
    if (status === 200) {
      yield put(VariableDataActions.getMonitoringDashboardSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o monitoramento de dashboard.')
      yield put(VariableDataActions.getMonitoringDashboardFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o monitoramento de dashboard.')
    yield put(VariableDataActions.getMonitoringDashboardFail())
  }
}

function* getMonitoringWatcher() {
  yield takeLatest(VariableDataTypes.GET_MONITORING_REQUEST, getMonitoring)
}

function* getDetourMonitoringWatcher() {
  yield takeLatest(
    VariableDataTypes.GET_DETOUR_MONITORING_REQUEST,
    getDetourMonitoring,
  )
}

function* getAlarmMonitoringWatcher() {
  yield takeLatest(
    VariableDataTypes.GET_ALARM_MONITORING_REQUEST,
    getAlarmMonitoring,
  )
}

function* getInsightMonitoringWatcher() {
  yield takeLatest(
    VariableDataTypes.GET_INSIGHT_MONITORING_REQUEST,
    getInsightMonitoring,
  )
}

function* getPlottingWatcher() {
  yield takeLatest(VariableDataTypes.GET_PLOTTING_REQUEST, getPlotting)
}

function* getMonitoringDashboardWatcher() {
  yield takeLatest(
    VariableDataTypes.GET_MONITORING_DASHBOARD_REQUEST,
    getMonitoringDashboard,
  )
}

export default function* rootSaga() {
  yield all([
    fork(getMonitoringWatcher),
    fork(getDetourMonitoringWatcher),
    fork(getAlarmMonitoringWatcher),
    fork(getInsightMonitoringWatcher),
    fork(getPlottingWatcher),
    fork(getMonitoringDashboardWatcher),
  ])
}

import React, { useEffect, useState } from 'react'
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import IntlMessages from '../../util/IntlMessages'
import NavMenuItem from './NavMenuItem'

const NavCollapse = props => {
  const { name, icon, children = [] } = props
  const [isMenuCollapseOpen, setIsMenuCollapseOpen] = useState(false)
  const history = useHistory()

  const isExpandableMenu = children && children.length > 0

  function handleMenuCollapse() {
    setIsMenuCollapseOpen(!isMenuCollapseOpen)
  }

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {boolean}
   */
  function isUrlInChildren(parent, url) {
    if (!parent.children) {
      return false
    }

    for (let i = 0; i < parent.children.length; i += 1) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], url)) {
          return true
        }
      }

      if (
        parent.children[i].link === url ||
        url.includes(parent.children[i].link)
      ) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (isUrlInChildren(props, history.location.pathname)) {
      setIsMenuCollapseOpen(true)
    } else {
      setIsMenuCollapseOpen(false)
    }
  }, [])

  history.listen(location => {
    if (isUrlInChildren(props, location.pathname)) {
      setIsMenuCollapseOpen(true)
    } else {
      setIsMenuCollapseOpen(false)
    }
  })

  const MenuItemChildren = isExpandableMenu && (
    <Collapse
      className="nav-collapse-item"
      in={isMenuCollapseOpen}
      timeout="auto"
    >
      <List component="div" disablePadding>
        {children.map(item => {
          item.fromCollapse = true
          switch (item.type) {
            case 'collapse':
              return <NavCollapse {...item} key={item.name} />
            case 'item':
              return <NavMenuItem {...item} key={item.name} />
            default:
              return []
          }
        })}
      </List>
    </Collapse>
  )

  return (
    <>
      <List component="div" className="nav-menu-item">
        <ListItem
          button
          className="prepend-icon nav-collapse-btn"
          onClick={handleMenuCollapse}
        >
          {!!icon && icon}
          <ListItemText
            className="nav-text"
            primary={<IntlMessages id={name} />}
          />
          {isExpandableMenu &&
            (isMenuCollapseOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      </List>
      {MenuItemChildren}
    </>
  )
}

export default NavCollapse

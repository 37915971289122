import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  Types as DetourTypes,
  Creators as DetourActions,
} from 'store/ducks/Detour'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getDetour({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/detours', payload)
    if (status === 200) {
      yield put(DetourActions.getDetourSuccess(data))
    } else {
      parseError(data || 'Erro ae listar o desvio.')
      yield put(DetourActions.getDetourFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae listar o desvio.')
    yield put(DetourActions.getDetourFail())
  }
}

function* postDetour({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/detours', payload)
    if (status === 200) {
      NotificationManager.success('Desvio criado com sucesso.')
      yield put(DetourActions.postDetourSuccess())
    } else {
      parseError(data || 'Erro ao criar o desvio.')
      yield put(DetourActions.postDetourFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao criar o desvio.')
    yield put(DetourActions.postDetourFail())
  }
}

function* showDetour({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/detours/${payload}`)
    if (status === 200) {
      yield put(DetourActions.showDetourSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o desvio.')
      yield put(DetourActions.showDetourFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o desvio.')
    yield put(DetourActions.showDetourFail())
  }
}

function* updateDetour({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/detours/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Desvio atualizado com sucesso.')
      yield put(DetourActions.updateDetourSuccess())
    } else {
      parseError(data || 'Erro ae atualizar o desvio.')
      yield put(DetourActions.updateDetourFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o desvio.')
    yield put(DetourActions.updateDetourFail())
  }
}

function* deleteDetour({ payload }) {
  try {
    const { data: detours } = yield select(state => state.detour)
    const { data, status } = yield call(api.delete, `/detours/${payload}`)
    if (status === 200 || status === 204) {
      const index = detours?.findIndex(item => item.id === payload)

      if (index !== -1) {
        detours.splice(index, 1)
      }
      yield put(DetourActions.getDetourSuccess(detours))
      yield put(DetourActions.deleteDetourSuccess())
      NotificationManager.success('Desvio deletado com sucesso.')
    } else {
      parseError(data || 'Erro ae deletar o desvio.')
      yield put(DetourActions.deleteDetourFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae deletar o desvio.')
    yield put(DetourActions.deleteDetourFail())
  }
}

function* getDetourWatcher() {
  yield takeLatest(DetourTypes.GET_DETOUR_REQUEST, getDetour)
}

function* postDetourWatcher() {
  yield takeLatest(DetourTypes.POST_DETOUR_REQUEST, postDetour)
}

function* showDetourWatcher() {
  yield takeLatest(DetourTypes.SHOW_DETOUR_REQUEST, showDetour)
}

function* updateDetourWatcher() {
  yield takeLatest(DetourTypes.UPDATE_DETOUR_REQUEST, updateDetour)
}

function* deleteDetourWatcher() {
  yield takeLatest(DetourTypes.DELETE_DETOUR_REQUEST, deleteDetour)
}

export default function* rootSaga() {
  yield all([
    fork(getDetourWatcher),
    fork(postDetourWatcher),
    fork(showDetourWatcher),
    fork(updateDetourWatcher),
    fork(deleteDetourWatcher),
  ])
}

import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  Types as AlarmTypes,
  Creators as AlarmActions,
} from 'store/ducks/Alarm'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getAlarms({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/alarms', payload)
    if (status === 200) {
      yield put(AlarmActions.getAlarmsSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o alarme.')
      yield put(AlarmActions.getAlarmsSuccess())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o alarme.')
    yield put(AlarmActions.getAlarmsSuccess())
  }
}

function* postAlarm({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/alarms', payload)
    if (status === 200) {
      NotificationManager.success('Alarme criado com sucesso.')
      yield put(AlarmActions.postAlarmSuccess())
    } else {
      parseError(data || 'Erro ao criar o alarme.')
      yield put(AlarmActions.postAlarmFail)
    }
  } catch (err) {
    parseError(err || 'Erro ao criar o alarme.')
    yield put(AlarmActions.postAlarmFail())
  }
}

function* showAlarm({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/alarms/${payload}`)
    if (status === 200) {
      yield put(AlarmActions.showAlarmSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o alarme.')
      yield put(AlarmActions.showAlarmFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o alarme.')
    yield put(AlarmActions.showAlarmFail())
  }
}

function* deleteAlarm({ payload }) {
  try {
    const { data: alarms } = yield select(state => state.alarm)
    const { data, status } = yield call(api.delete, `/alarms/${payload}`)
    if (status === 200 || status === 204) {
      const index = alarms?.findIndex(item => item.id === payload)

      if (index !== -1) {
        alarms.splice(index, 1)
      }
      yield put(AlarmActions.getAlarmsSuccess(alarms))
      yield put(AlarmActions.deleteAlarmSuccess(data))
      NotificationManager.success('Alarme deletado com sucesso.')
    } else {
      parseError(data || 'Erro ae deletar o alarme.')
      yield put(AlarmActions.deleteAlarmFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae deletar o alarme.')
    yield put(AlarmActions.deleteAlarmFail())
  }
}

function* updateAlarm({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/alarms/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Alarme atualizado com sucesso.')
      yield put(AlarmActions.updateAlarmSuccess())
    } else {
      parseError(data || 'Erro ae atualizar o alarme.')
      yield put(AlarmActions.updateAlarmFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o alarme.')
    yield put(AlarmActions.updateAlarmFail())
  }
}

function* getAlarmsWatcher() {
  yield takeLatest(AlarmTypes.GET_ALARMS_REQUEST, getAlarms)
}

function* postAlarmWatcher() {
  yield takeLatest(AlarmTypes.POST_ALARM_REQUEST, postAlarm)
}

function* showAlarmWatcher() {
  yield takeLatest(AlarmTypes.SHOW_ALARM_REQUEST, showAlarm)
}

function* deleteAlarmWatcher() {
  yield takeLatest(AlarmTypes.DELETE_ALARM_REQUEST, deleteAlarm)
}

function* updateAlarmWatcher() {
  yield takeLatest(AlarmTypes.UPDATE_ALARM_REQUEST, updateAlarm)
}

export default function* rootSaga() {
  yield all([
    fork(getAlarmsWatcher),
    fork(postAlarmWatcher),
    fork(showAlarmWatcher),
    fork(deleteAlarmWatcher),
    fork(updateAlarmWatcher),
  ])
}

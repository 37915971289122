export const Types = {
  GET_CONFIGS_REQUEST: 'GET_CONFIGS_REQUEST',
  GET_CONFIGS_SUCCESS: 'GET_CONFIGS_SUCCESS',
  GET_CONFIGS_FAIL: 'GET_CONFIGS_FAIL',
  GET_CONFIGS_CLEAN: 'GET_CONFIGS_CLEAN',

  GET_LOGS_REQUEST: 'GET_LOGS_REQUEST',
  GET_LOGS_SUCCESS: 'GET_LOGS_SUCCESS',
  GET_LOGS_FAIL: 'GET_LOGS_FAIL',
  GET_LOGS_CLEAN: 'GET_LOGS_CLEAN',
};

const INITIAL_STATE = {
  configsData: null,
  configsLoading: false,
  configsError: false,

  logsData: null,
  logsLoading: false,
  logsError: false,
};

export default function VariableData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CONFIGS_REQUEST:
      return {
        ...state,
        configsLoading: true,
        configsError: false,
      };
    case Types.GET_CONFIGS_SUCCESS:
      return {
        ...state,
        configsLoading: false,
        configsError: false,
        configsData: action.payload,
      };
    case Types.GET_CONFIGS_FAIL:
      return {
        ...state,
        configsLoading: false,
        configsError: true,
      };
    case Types.GET_CONFIGS_CLEAN:
      return {
        ...state,
        configsLoading: false,
        configsError: false,
        configsData: null,
      };

    case Types.GET_LOGS_REQUEST:
      return {
        ...state,
        logsLoading: true,
        logsError: false,
      };
    case Types.GET_LOGS_SUCCESS:
      return {
        ...state,
        logsLoading: false,
        logsError: false,
        logsData: action.payload,
      };
    case Types.GET_LOGS_FAIL:
      return {
        ...state,
        logsLoading: false,
        logsError: true,
      };
    case Types.GET_LOGS_CLEAN:
      return {
        ...state,
        logsLoading: false,
        logsError: false,
        logsData: null,
      };
    default:
      return state;
  }
}

export const Creators = {
  getConfigsRequest: (id, payload) => ({
    type: Types.GET_CONFIGS_REQUEST,
    id,
    payload,
  }),
  getConfigsSuccess: payload => ({
    type: Types.GET_CONFIGS_SUCCESS,
    payload,
  }),
  getConfigsFail: () => ({
    type: Types.GET_CONFIGS_FAIL,
  }),
  getConfigsClean: () => ({
    type: Types.GET_CONFIGS_CLEAN,
  }),

  getLogsRequest: (id, payload) => ({
    type: Types.GET_LOGS_REQUEST,
    id,
    payload,
  }),
  getLogsSuccess: payload => ({
    type: Types.GET_LOGS_SUCCESS,
    payload,
  }),
  getLogsFail: () => ({
    type: Types.GET_LOGS_FAIL,
  }),
  getLogsClean: () => ({
    type: Types.GET_LOGS_CLEAN,
  }),
};

export const Types = {
  // GET LIST OF ALARM DATA
  GET_ALARM_DATA_DASHBOARD_REQUEST: 'GET_ALARM_DATA_DASHBOARD_REQUEST',
  GET_ALARM_DATA_MONITORING_REQUEST: 'GET_ALARM_DATA_MONITORING_REQUEST',
  GET_ALARM_DATA_HISTORY_REQUEST: 'GET_ALARM_DATA_HISTORY_REQUEST',
  GET_ALARM_DATA_SUCCESS: 'GET_ALARM_DATA_SUCCESS',
  GET_ALARM_DATA_FAIL: 'GET_ALARM_DATA_FAIL',
  GET_ALARM_DATA_CLEAN: 'GET_ALARM_DATA_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
};

export default function AlarmData(state = INITIAL_STATE, action) {
  switch (action.type) {
    // GET LIST OF ALARM DATA
    case Types.GET_ALARM_DATA_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_ALARM_DATA_MONITORING_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_ALARM_DATA_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_ALARM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_ALARM_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Types.GET_ALARM_DATA_CLEAN:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET LIST OF ALARM DATA
  getAlarmDataDashboardRequest: payload => ({
    type: Types.GET_ALARM_DATA_DASHBOARD_REQUEST,
    payload,
  }),
  getAlarmDataMonitoringRequest: payload => ({
    type: Types.GET_ALARM_DATA_MONITORING_REQUEST,
    payload,
  }),
  getAlarmDataHistoryRequest: payload => ({
    type: Types.GET_ALARM_DATA_HISTORY_REQUEST,
    payload,
  }),
  getAlarmDataSuccess: payload => ({
    type: Types.GET_ALARM_DATA_SUCCESS,
    payload,
  }),
  getAlarmDataFail: () => ({
    type: Types.GET_ALARM_DATA_FAIL,
  }),
  getAlarmDataClean: () => ({
    type: Types.GET_ALARM_DATA_CLEAN,
  }),
};

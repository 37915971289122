export const Types = {
  GET_CONNECTION_ALERTS_REQUEST: 'GET_CONNECTION_ALERTS_REQUEST',
  GET_CONNECTION_ALERTS_SUCCESS: 'GET_CONNECTION_ALERTS_SUCCESS',
  GET_CONNECTION_ALERTS_FAIL: 'GET_CONNECTION_ALERTS_FAIL',

  SHOW_CONNECTION_ALERT_REQUEST: 'SHOW_CONNECTION_ALERT_REQUEST',
  SHOW_CONNECTION_ALERT_SUCCESS: 'SHOW_CONNECTION_ALERT_SUCCESS',
  SHOW_CONNECTION_ALERT_FAIL: 'SHOW_CONNECTION_ALERT_FAIL',

  UPDATE_STATUS_CONNECTION_ALERT_REQUEST:
    'UPDATE_STATUS_CONNECTION_ALERT_REQUEST',
  UPDATE_STATUS_CONNECTION_ALERT_SUCCESS:
    'UPDATE_STATUS_CONNECTION_ALERT_SUCCESS',
  UPDATE_STATUS_CONNECTION_ALERT_FAIL: 'UPDATE_STATUS_CONNECTION_ALERT_FAIL',
}

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  showLoading: false,
  showError: false,
  showData: null,

  updateStatusLoading: false,
  updateStatusError: false,
  updateStatusSuccess: false,
}

export default function CONNECTION_ALERT(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CONNECTION_ALERTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case Types.GET_CONNECTION_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      }
    case Types.GET_CONNECTION_ALERTS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case Types.SHOW_CONNECTION_ALERT_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case Types.SHOW_CONNECTION_ALERT_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      }
    case Types.SHOW_CONNECTION_ALERT_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      }

    case Types.UPDATE_STATUS_CONNECTION_ALERT_REQUEST:
      return {
        ...state,
        updateStatusLoading: true,
        updateStatusError: false,
        updateStatusSuccess: false,
      }
    case Types.UPDATE_STATUS_CONNECTION_ALERT_SUCCESS:
      return {
        ...state,
        updateStatusLoading: false,
        updateStatusError: false,
        updateStatusSuccess: true,
      }
    case Types.UPDATE_STATUS_CONNECTION_ALERT_FAIL:
      return {
        ...state,
        updateStatusLoading: false,
        updateStatusError: true,
        updateStatusSuccess: false,
      }

    default:
      return state
  }
}

export const Creators = {
  // GET
  getConnectionAlertsRequest: payload => ({
    type: Types.GET_CONNECTION_ALERTS_REQUEST,
    payload,
  }),
  getConnectionAlertsSuccess: payload => ({
    type: Types.GET_CONNECTION_ALERTS_SUCCESS,
    payload,
  }),
  getConnectionAlertsFail: () => ({
    type: Types.GET_CONNECTION_ALERTS_FAIL,
  }),

  // SHOW
  showConnectionAlertRequest: payload => ({
    type: Types.SHOW_CONNECTION_ALERT_REQUEST,
    payload,
  }),
  showConnectionAlertSuccess: payload => ({
    type: Types.SHOW_CONNECTION_ALERT_SUCCESS,
    payload,
  }),
  showConnectionAlertFail: () => ({
    type: Types.SHOW_CONNECTION_ALERT_FAIL,
  }),

  // UPDATE STATUS
  updateStatusConnectionAlertRequest: payload => ({
    type: Types.UPDATE_STATUS_CONNECTION_ALERT_REQUEST,
    payload,
  }),
  updateStatusConnectionAlertSuccess: payload => ({
    type: Types.UPDATE_STATUS_CONNECTION_ALERT_SUCCESS,
    payload,
  }),
  updateStatusConnectionAlertFail: () => ({
    type: Types.UPDATE_STATUS_CONNECTION_ALERT_FAIL,
  }),
}

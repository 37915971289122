import axios from 'axios';
import { getToken } from './index';

// console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async config => {
  const cfg = config;
  const token = getToken();
  // console.log('token', token);
  cfg.headers['Accept-Language'] = 'pt';
  if (token) {
    cfg.headers.Authorization = `Bearer ${token}`;
  }
  return cfg;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.data?.errors) {
      console.log(error.response);
    }
    if (error?.config && error?.response && error?.response?.status === 401) {
      /* TODO: REFRESH TOKEN */
    }

    return Promise.reject(error);
  }
);

export default api;

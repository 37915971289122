import React from 'react'
import {
  AccountBox,
  Assessment,
  Ballot,
  Dashboard,
  Group,
  LocationCity,
  TrendingUp,
} from '@material-ui/icons'
import CustomScrollbars from 'util/CustomScrollbars'
import Navigation from '../../components/Navigation'
import { getTypeUser } from '../../util'

const SideBarContent = () => {
  const menu = [
    {
      name: 'sidebar.dashboard',
      type: 'item',
      link: '/dashboard',
      icon: <Dashboard />,
      auth: ['root', 'admin'],
    },
    {
      name: 'sidebar.sites',
      type: 'item',
      link: '/sites',
      icon: <LocationCity />,
      auth: ['root', 'admin', 'engineer', 'maintenance', 'customer'],
    },
    {
      name: 'sidebar.monitoring',
      type: 'collapse',
      icon: <Assessment />,
      auth: ['root', 'admin', 'engineer', 'maintenance', 'customer'],
      children: [
        {
          name: 'sidebar.monitoring.alarms',
          type: 'item',
          link: '/monitoring/alarms',
        },
        {
          name: 'sidebar.monitoring.detours',
          type: 'item',
          link: '/monitoring/detours',
        },
        {
          name: 'sidebar.monitoring.insights',
          type: 'item',
          link: '/monitoring/insights',
        },
        {
          name: 'sidebar.monitoring.connections',
          type: 'item',
          link: '/monitoring/connections',
        },
      ],
    },
    {
      name: 'sidebar.charts',
      type: 'item',
      link: '/charts',
      icon: <TrendingUp />,
      auth: ['root', 'admin', 'engineer', 'maintenance', 'customer'],
    },
    {
      name: 'sidebar.teams',
      type: 'item',
      link: '/teams/settings',
      icon: <Group />,
      auth: ['root', 'admin', 'engineer'],
    },
    {
      name: 'sidebar.users',
      type: 'item',
      link: '/users/settings',
      icon: <AccountBox />,
      auth: ['root', 'admin', 'engineer'],
    },
    {
      name: 'sidebar.templates',
      type: 'item',
      link: '/templates/settings',
      icon: <Ballot />,
      auth: ['root'],
    },
  ]

  const getNavigation = () => {
    const userType = getTypeUser()?.split(',')
    return menu.filter(menu =>
      userType?.some(userType => menu.auth.indexOf(userType) !== -1),
    )
  }

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={getNavigation()} />
    </CustomScrollbars>
  )
}

export default SideBarContent

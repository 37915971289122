import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'store/ducks/Setting';
import SideBarContent from './SideBarContent';

const SideBar = () => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const { drawerType, navigationStyle } = useSelector(
    ({ settings }) => settings
  );
  const { navCollapsed } = useSelector(({ common }) => common);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, [dispatch]);

  const onToggleCollapsedNav = e => {
    dispatch(toggleCollapsedNav());
  };

  // eslint-disable-next-line no-nested-ternary
  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = 'temporary';
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
    type = 'temporary';
  }

  console.log('navCollapsed in drawer file', type, navCollapsed);
  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
        <UserInfo />
        <SideBarContent />
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);

export const Types = {
  GET_TEAMS_REQUEST: 'GET_TEAMS_REQUEST',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
  GET_TEAMS_FAIL: 'GET_TEAMS_FAIL',

  PUT_TEAM_REQUEST: 'PUT_TEAM_REQUEST',
  PUT_TEAM_SUCCESS: 'PUT_TEAM_SUCCESS',
  PUT_TEAM_FAIL: 'PUT_TEAM_FAIL',
  PUT_TEAM_CLEAN: 'PUT_TEAM_CLEAN',

  POST_TEAM_REQUEST: 'POST_TEAM_REQUEST',
  POST_TEAM_SUCCESS: 'POST_TEAM_SUCCESS',
  POST_TEAM_FAIL: 'POST_TEAM_FAIL',
  POST_TEAM_CLEAN: 'POST_TEAM_CLEAN',

  SHOW_TEAM_REQUEST: 'SHOW_TEAM_REQUEST',
  SHOW_TEAM_SUCCESS: 'SHOW_TEAM_SUCCESS',
  SHOW_TEAM_FAIL: 'SHOW_TEAM_FAIL',
  SHOW_TEAM_CLEAN: 'SHOW_TEAM_CLEAN',

  DELETE_TEAM_REQUEST: 'DELETE_TEAM_REQUEST',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_FAIL: 'DELETE_TEAM_FAIL',
  DELETE_TEAM_CLEAN: 'DELETE_TEAM_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  putLoading: false,
  putError: false,
  putSuccess: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  showLoading: false,
  showError: false,
  showData: null,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,
};

export default function teams(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case Types.GET_TEAMS_FAIL:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case Types.PUT_TEAM_REQUEST:
      return {
        ...state,
        putLoading: true,
        putError: false,
        putSuccess: false,
      };
    case Types.PUT_TEAM_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putError: false,
        putSuccess: true,
      };
    case Types.PUT_TEAM_FAIL:
      return {
        ...state,
        putLoading: false,
        putError: true,
        putSuccess: false,
      };
    case Types.PUT_TEAM_CLEAN:
      return {
        ...state,
        putLoading: false,
        putError: false,
        putSuccess: false,
      };

    case Types.POST_TEAM_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      };
    case Types.POST_TEAM_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      };
    case Types.POST_TEAM_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      };
    case Types.POST_TEAM_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      };

    case Types.SHOW_TEAM_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      };
    case Types.SHOW_TEAM_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      };
    case Types.SHOW_TEAM_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      };
    case Types.SHOW_TEAM_CLEAN:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: null,
      };

    case Types.DELETE_TEAM_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      };
    case Types.DELETE_TEAM_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_TEAM_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getTeamsRequest: payload => ({
    type: Types.GET_TEAMS_REQUEST,
    payload,
  }),
  getTeamsSuccess: payload => ({
    type: Types.GET_TEAMS_SUCCESS,
    payload,
  }),
  getTeamsFail: () => ({
    type: Types.GET_TEAMS_FAIL,
  }),

  // PUT
  putTeamRequest: payload => ({
    type: Types.PUT_TEAM_REQUEST,
    payload,
  }),
  putTeamSuccess: payload => ({
    type: Types.PUT_TEAM_SUCCESS,
    payload,
  }),
  putTeamFail: () => ({
    type: Types.PUT_TEAM_FAIL,
  }),
  putTeamClean: () => ({
    type: Types.PUT_TEAM_CLEAN,
  }),

  // POST
  postTeamRequest: payload => ({
    type: Types.POST_TEAM_REQUEST,
    payload,
  }),
  postTeamSuccess: payload => ({
    type: Types.POST_TEAM_SUCCESS,
    payload,
  }),
  postTeamFail: () => ({
    type: Types.POST_TEAM_FAIL,
  }),
  postTeamClean: () => ({
    type: Types.POST_TEAM_CLEAN,
  }),

  // SHOW
  showTeamRequest: payload => ({
    type: Types.SHOW_TEAM_REQUEST,
    payload,
  }),
  showTeamSuccess: payload => ({
    type: Types.SHOW_TEAM_SUCCESS,
    payload,
  }),
  showTeamFail: () => ({
    type: Types.SHOW_TEAM_FAIL,
  }),
  showTeamClean: () => ({
    type: Types.SHOW_TEAM_CLEAN,
  }),

  // DELETE
  deleteTeamRequest: payload => ({
    type: Types.DELETE_TEAM_REQUEST,
    payload,
  }),
  deleteTeamSuccess: payload => ({
    type: Types.DELETE_TEAM_SUCCESS,
    payload,
  }),
  deleteTeamFail: () => ({
    type: Types.DELETE_TEAM_FAIL,
  }),
  deleteTeamError: () => ({
    type: Types.DELETE_TEAM_CLEAN,
  }),
};

import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { Types as TeamTypes, Creators as TeamActions } from 'store/ducks/Team'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getTeams({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/teams', payload)
    if (status === 200) {
      yield put(TeamActions.getTeamsSuccess(data))
    } else {
      parseError(data || 'Erro ao listar time.')
      yield put(TeamActions.getTeamsFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao listar time.')
    yield put(TeamActions.getTeamsFail())
  }
}

function* putTeam({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/teams/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Time editado com sucesso.')
      yield put(TeamActions.putTeamSuccess(data))
    } else {
      parseError(data || 'Erro ao editar time.')
      yield put(TeamActions.putTeamFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar time.')
    yield put(TeamActions.putTeamFail())
  }
}

function* postTeam({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/teams', payload)
    if (status === 200) {
      NotificationManager.success('Time criado com sucesso.')
      yield put(TeamActions.postTeamSuccess())
    } else {
      parseError(data || 'Erro ao criar time.')
      yield put(TeamActions.postTeamFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao criar time.')
    yield put(TeamActions.postTeamFail())
  }
}

function* showTeam({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/teams/${payload}?with=customer`,
    )
    if (status === 200) {
      yield put(TeamActions.showTeamSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o time.')
      yield put(TeamActions.showTeamFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o time.')
    yield put(TeamActions.showTeamFail())
  }
}

function* deleteTeam({ payload }) {
  try {
    const { data: teams } = yield select(state => state.team)
    const { data, status } = yield call(api.delete, `/teams/${payload}`)
    if (status === 200 || status === 204) {
      const index = teams?.findIndex(item => item.id === payload)
      if (index !== -1) {
        teams.splice(index, 1)
      }

      yield put(TeamActions.getTeamsSuccess(teams))
      yield put(TeamActions.deleteTeamSuccess())
      NotificationManager.success('Time deletado com sucesso.')
    } else {
      parseError(data || 'Erro ao deletar o time.')
      yield put(TeamActions.deleteTeamFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao deletar o time.')
    yield put(TeamActions.deleteTeamFail())
  }
}

function* getTeamsWatcher() {
  yield takeLatest(TeamTypes.GET_TEAMS_REQUEST, getTeams)
}

function* putTeamWatcher() {
  yield takeLatest(TeamTypes.PUT_TEAM_REQUEST, putTeam)
}

function* postTeamWatcher() {
  yield takeLatest(TeamTypes.POST_TEAM_REQUEST, postTeam)
}

function* showTeamWatcher() {
  yield takeLatest(TeamTypes.SHOW_TEAM_REQUEST, showTeam)
}

function* deleteTeamWatcher() {
  yield takeLatest(TeamTypes.DELETE_TEAM_REQUEST, deleteTeam)
}

export default function* rootSaga() {
  yield all([
    fork(getTeamsWatcher),
    fork(putTeamWatcher),
    fork(postTeamWatcher),
    fork(showTeamWatcher),
    fork(deleteTeamWatcher),
  ])
}

import { NotificationManager } from 'react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Types as VariableConfigTypes,
  Creators as VariableConfigActions,
} from 'store/ducks/VariableConfig';
import api from 'util/Api';
import { parseError } from 'util/index';

function* getConfigs({ id, payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/variableConfig/${id}/configs`,
      payload
    );
    if (status === 200) {
      yield put(VariableConfigActions.getConfigsSuccess(data));
    } else {
      parseError(data || 'Erro ao carregar as configurações.');
      yield put(VariableConfigActions.getConfigsFail());
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar as configurações.');
    yield put(VariableConfigActions.getConfigsFail());
  }
}

function* getLogs({ id, payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/variableConfig/${id}/logs`,
      payload
    );
    if (status === 200) {
      yield put(VariableConfigActions.getLogsSuccess(data));
    } else {
      parseError(data || 'Erro ao carregar os logs.');
      yield put(VariableConfigActions.getLogsFail());
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar os logs.');
    yield put(VariableConfigActions.getLogsFail());
  }
}

function* getConfigsWatcher() {
  yield takeLatest(VariableConfigTypes.GET_CONFIGS_REQUEST, getConfigs);
}

function* getLogsWatcher() {
  yield takeLatest(VariableConfigTypes.GET_LOGS_REQUEST, getLogs);
}

export default function* rootSaga() {
  yield all([fork(getConfigsWatcher), fork(getLogsWatcher)]);
}

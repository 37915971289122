import { all } from 'redux-saga/effects'

import authSagas from './Auth'
import userSagas from './User'
import teamSagas from './Team'
import variableSagas from './Variable'
import alarmSagas from './Alarm'
import detourSagas from './Detour'
import gasLackSagas from './GasLackInsight'
import customerSagas from './Customer'
import equipamentSagas from './Equipament'
import templateSagas from './Template'
import moldSagas from './Mold'
import variableDataSagas from './VariableData'
import variableConfigsSagas from './VariableConfig'
import statuses from './Statuses'
import alarmDataSagas from './AlarmData'
import dashboardSagas from './Dashboard'
import intersectionSagas from './Intersection'
import subscriptionSagas from './Subscription'
import connectionAlertSagas from './ConnectionAlert'

export default function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    teamSagas(),
    variableSagas(),
    alarmSagas(),
    detourSagas(),
    gasLackSagas(),
    customerSagas(),
    equipamentSagas(),
    templateSagas(),
    moldSagas(),
    variableDataSagas(),
    variableConfigsSagas(),
    statuses(),
    alarmDataSagas(),
    dashboardSagas(),
    intersectionSagas(),
    subscriptionSagas(),
    connectionAlertSagas(),
  ])
}

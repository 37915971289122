import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  Types as VariableTypes,
  Creators as VariableActions,
} from 'store/ducks/Variable'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getVariables({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/variables', payload)
    if (status === 200) {
      yield put(VariableActions.getVariablesSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar as variáveis.')
      yield put(VariableActions.getVariablesFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar as variáveis.')
    yield put(VariableActions.getVariablesFail())
  }
}

function* postVariable({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/variables', payload.data)
    if (status === 200) {
      NotificationManager.success('Variável criado com sucesso.')
      yield put(VariableActions.postVariableSuccess())
    } else {
      parseError(data || 'Erro ao criar a variável.')
      yield put(VariableActions.postVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao criar a variável.')
    yield put(VariableActions.postVariableFail())
  }
}

function* showVariable({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/variables/${payload}?with=roles,intersections&hasIcon=true`,
    )
    if (status === 200) {
      yield put(VariableActions.showVariableSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar a variável.')
      yield put(VariableActions.showVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar a variável.')
    yield put(VariableActions.showVariableFail())
  }
}

function* updateVariable({ payload }) {
  try {
    const { status, data } = yield call(
      api.put,
      `/variables/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Variável atualizada com sucesso.')
      yield put(VariableActions.updateVariableSuccess())
    } else {
      parseError(data || 'Erro ae atualizar a variável.')
      yield put(VariableActions.updateVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar a variável.')
    yield put(VariableActions.updateVariableFail())
  }
}

function* deleteVariable({ payload }) {
  try {
    const { data: variables } = yield select(state => state.variable)
    const { data, status } = yield call(api.delete, `/variables/${payload}`)
    if (status === 200 || status === 204) {
      const index = variables?.findIndex(item => item.id === payload)

      if (index !== -1) {
        variables.splice(index, 1)
      }
      yield put(VariableActions.getVariablesSuccess(variables))
      yield put(VariableActions.deleteVariableSuccess())
      NotificationManager.success('Variável deletada com sucesso.')
    } else {
      parseError(data || 'Erro ae deletar a variável.')
      yield put(VariableActions.deleteVariableFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae deletar a variável.')
    yield put(VariableActions.deleteVariableFail())
  }
}

function* getVariablesWatcher() {
  yield takeLatest(VariableTypes.GET_VARIABLES_REQUEST, getVariables)
}

function* postVariableWatcher() {
  yield takeLatest(VariableTypes.POST_VARIABLE_REQUEST, postVariable)
}

function* showVariableWatcher() {
  yield takeLatest(VariableTypes.SHOW_VARIABLE_REQUEST, showVariable)
}

function* updateVariableWatcher() {
  yield takeLatest(VariableTypes.UPDATE_VARIABLE_REQUEST, updateVariable)
}

function* deleteVariableWatcher() {
  yield takeLatest(VariableTypes.DELETE_VARIABLE_REQUEST, deleteVariable)
}

export default function* rootSaga() {
  yield all([
    fork(getVariablesWatcher),
    fork(postVariableWatcher),
    fork(showVariableWatcher),
    fork(updateVariableWatcher),
    fork(deleteVariableWatcher),
  ])
}

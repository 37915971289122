export const Types = {
  GET_ALARMS_REQUEST: 'GET_ALARMS_REQUEST',
  GET_ALARMS_SUCCESS: 'GET_ALARMS_SUCCESS',
  GET_ALARMS_FAIL: 'GET_ALARMS_FAIL',

  POST_ALARM_REQUEST: 'POST_ALARM_REQUEST',
  POST_ALARM_SUCCESS: 'POST_ALARM_SUCCESS',
  POST_ALARM_FAIL: 'POST_ALARM_FAIL',
  POST_ALARM_CLEAN: 'POST_ALARM_CLEAN',

  SHOW_ALARM_REQUEST: 'SHOW_ALARM_REQUEST',
  SHOW_ALARM_SUCCESS: 'SHOW_ALARM_SUCCESS',
  SHOW_ALARM_FAIL: 'SHOW_ALARM_FAIL',

  DELETE_ALARM_REQUEST: 'DELETE_ALARM_REQUEST',
  DELETE_ALARM_SUCCESS: 'DELETE_ALARM_SUCCESS',
  DELETE_ALARM_FAIL: 'DELETE_ALARM_FAIL',
  DELETE_ALARM_CLEAN: 'DELETE_ALARM_CLEAN',

  UPDATE_ALARM_REQUEST: 'UPDATE_ALARM_REQUEST',
  UPDATE_ALARM_SUCCESS: 'UPDATE_ALARM_SUCCESS',
  UPDATE_ALARM_FAIL: 'UPDATE_ALARM_FAIL',
  UPDATE_ALARM_CLEAN: 'UPDATE_ALARM_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  showLoading: false,
  showError: false,
  showData: null,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,
};

export default function Alarm(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ALARMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_ALARMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_ALARMS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.POST_ALARM_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      };
    case Types.POST_ALARM_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      };
    case Types.POST_ALARM_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      };
    case Types.POST_ALARM_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      };

    case Types.SHOW_ALARM_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      };
    case Types.SHOW_ALARM_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      };
    case Types.SHOW_ALARM_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      };

    case Types.DELETE_ALARM_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_ALARM_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_ALARM_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_ALARM_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.UPDATE_ALARM_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_ALARM_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_ALARM_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_ALARM_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getAlarmsRequest: payload => ({
    type: Types.GET_ALARMS_REQUEST,
    payload,
  }),
  getAlarmsSuccess: payload => ({
    type: Types.GET_ALARMS_SUCCESS,
    payload,
  }),
  getAlarmsFail: () => ({
    type: Types.GET_ALARMS_FAIL,
  }),

  // POST
  postAlarmRequest: payload => ({
    type: Types.POST_ALARM_REQUEST,
    payload,
  }),
  postAlarmSuccess: payload => ({
    type: Types.POST_ALARM_SUCCESS,
    payload,
  }),
  postAlarmFail: () => ({
    typee: Types.POST_ALARM_FAIL,
  }),
  postAlarmClean: () => ({
    type: Types.POST_ALARM_CLEAN,
  }),

  // SHOW
  showAlarmRequest: payload => ({
    type: Types.SHOW_ALARM_REQUEST,
    payload,
  }),
  showAlarmSuccess: payload => ({
    type: Types.SHOW_ALARM_SUCCESS,
    payload,
  }),
  showAlarmFail: () => ({
    type: Types.SHOW_ALARM_FAIL,
  }),

  // DELETE
  deleteAlarmRequest: payload => ({
    type: Types.DELETE_ALARM_REQUEST,
    payload,
  }),
  deleteAlarmSuccess: payload => ({
    type: Types.DELETE_ALARM_SUCCESS,
    payload,
  }),
  deleteAlarmFail: () => ({
    type: Types.DELETE_ALARM_FAIL,
  }),
  deleteAlarmClean: () => ({
    type: Types.DELETE_ALARM_CLEAN,
  }),

  // UPDATE
  updateAlarmRequest: payload => ({
    type: Types.UPDATE_ALARM_REQUEST,
    payload,
  }),
  updateAlarmSuccess: payload => ({
    type: Types.UPDATE_ALARM_SUCCESS,
    payload,
  }),
  updateAlarmFail: () => ({
    type: Types.UPDATE_ALARM_FAIL,
  }),
  updateAlarmClean: () => ({
    type: Types.UPDATE_ALARM_CLEAN,
  }),
};

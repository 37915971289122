export const Types = {
  GET_DETOUR_REQUEST: 'GET_DETOUR_REQUEST',
  GET_DETOUR_SUCCESS: 'GET_DETOUR_SUCCESS',
  GET_DETOUR_FAIL: 'GET_DETOUR_FAIL',

  POST_DETOUR_REQUEST: 'POST_DETOUR_REQUEST',
  POST_DETOUR_SUCCESS: 'POST_DETOUR_SUCCESS',
  POST_DETOUR_FAIL: 'POST_DETOUR_FAIL',
  POST_DETOUR_CLEAN: 'POST_DETOUR_CLEAN',

  SHOW_DETOUR_REQUEST: 'SHOW_DETOUR_REQUEST',
  SHOW_DETOUR_SUCCESS: 'SHOW_DETOUR_SUCCESS',
  SHOW_DETOUR_FAIL: 'SHOW_DETOUR_FAIL',
  SHOW_DETOUR_CLEAN: 'SHOW_DETOUR_CLEAN',

  UPDATE_DETOUR_REQUEST: 'UPDATE_DETOUR_REQUEST',
  UPDATE_DETOUR_SUCCESS: 'UPDATE_DETOUR_SUCCESS',
  UPDATE_DETOUR_FAIL: 'UPDATE_DETOUR_FAIL',
  UPDATE_DETOUR_CLEAN: 'UPDATE_DETOUR_CLEAN',

  DELETE_DETOUR_REQUEST: 'DELETE_DETOUR_REQUEST',
  DELETE_DETOUR_SUCCESS: 'DELETE_DETOUR_SUCCESS',
  DELETE_DETOUR_FAIL: 'DELETE_DETOUR_FAIL',
  DELETE_DETOUR_CLEAN: 'DELETE_DETOUR_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  showLoading: false,
  showError: false,
  showData: null,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,
};

export default function Detour(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_DETOUR_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_DETOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_DETOUR_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.POST_DETOUR_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      };
    case Types.POST_DETOUR_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      };
    case Types.POST_DETOUR_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      };
    case Types.POST_DETOUR_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      };

    case Types.SHOW_DETOUR_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      };
    case Types.SHOW_DETOUR_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      };
    case Types.SHOW_DETOUR_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      };
    case Types.SHOW_DETOUR_CLEAN:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: null,
      };

    case Types.UPDATE_DETOUR_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_DETOUR_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_DETOUR_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_DETOUR_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    case Types.DELETE_DETOUR_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_DETOUR_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_DETOUR_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_DETOUR_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getDetourRequest: payload => ({
    type: Types.GET_DETOUR_REQUEST,
    payload,
  }),
  getDetourSuccess: payload => ({
    type: Types.GET_DETOUR_SUCCESS,
    payload,
  }),
  getDetourFail: () => ({
    type: Types.GET_DETOUR_FAIL,
  }),

  // POST
  postDetourRequest: payload => ({
    type: Types.POST_DETOUR_REQUEST,
    payload,
  }),
  postDetourSuccess: payload => ({
    type: Types.POST_DETOUR_SUCCESS,
    payload,
  }),
  postDetourFail: () => ({
    type: Types.POST_DETOUR_FAIL,
  }),
  postDetourClean: () => ({
    type: Types.POST_DETOUR_CLEAN,
  }),

  // SHOW
  showDetourRequest: payload => ({
    type: Types.SHOW_DETOUR_REQUEST,
    payload,
  }),
  showDetourSuccess: payload => ({
    type: Types.SHOW_DETOUR_SUCCESS,
    payload,
  }),
  showDetourFail: () => ({
    type: Types.SHOW_DETOUR_FAIL,
  }),
  showDetourClean: () => ({
    type: Types.SHOW_DETOUR_CLEAN,
  }),

  // UPDATE
  updateDetourRequest: payload => ({
    type: Types.UPDATE_DETOUR_REQUEST,
    payload,
  }),
  updateDetourSuccess: payload => ({
    type: Types.UPDATE_DETOUR_SUCCESS,
    payload,
  }),
  updateDetourFail: () => ({
    type: Types.UPDATE_DETOUR_FAIL,
  }),
  updateDetourClean: () => ({
    type: Types.UPDATE_DETOUR_CLEAN,
  }),

  // DELETE
  deleteDetourRequest: payload => ({
    type: Types.DELETE_DETOUR_REQUEST,
    payload,
  }),
  deleteDetourSuccess: payload => ({
    type: Types.DELETE_DETOUR_SUCCESS,
    payload,
  }),
  deleteDetourFail: () => ({
    type: Types.DELETE_DETOUR_FAIL,
  }),
  deleteDetourClean: () => ({
    type: Types.DELETE_DETOUR_CLEAN,
  }),
};

import { NotificationManager } from 'react-notifications';
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import {
  Types as DashboardTypes,
  Creators as DashboardActions,
} from 'store/ducks/Dashboard';
import api from 'util/Api';
import { parseError } from 'util/index';

function* getDashboard({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/variableDashboard', payload);
    if (status === 200) {
      yield put(DashboardActions.getVariableDashboardSuccess(data));
    } else {
      parseError(data || 'Falha ao listar variáveis de dashboard.');
      yield put(DashboardActions.getVariableDashboardFail());
    }
  } catch (err) {
    parseError(err || 'Falha ao listar variáveis de dashboard.');
    yield put(DashboardActions.getVariableDashboardFail());
  }
}

function* postDashboard({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      '/variableDashboard',
      payload
    );
    if (status === 200) {
      yield put(DashboardActions.postVariableDashboardSuccess());
    } else {
      parseError(data || 'Falha ao criar variáveis de dashboard.');
      yield put(DashboardActions.postVariableDashboardFail());
    }
  } catch (err) {
    parseError(err || 'Falha ao criar variáveis de dashboard.');
    yield put(DashboardActions.postVariableDashboardFail());
  }
}

function* getDashboardWatcher() {
  yield takeLatest(DashboardTypes.GET_VARIABLE_DASHBOARD_REQUEST, getDashboard);
}

function* postDashboardWathcer() {
  yield takeLatest(
    DashboardTypes.POST_VARIABLE_DASHBOARD_REQUEST,
    postDashboard
  );
}

export default function* rootSaga() {
  yield all([fork(getDashboardWatcher), fork(postDashboardWathcer)]);
}

export const Types = {
  // GET MONITORING
  GET_MONITORING_REQUEST: 'GET_MONITORING_REQUEST',
  GET_MONITORING_SUCCESS: 'GET_MONITORING_SUCCESS',
  GET_MONITORING_FAIL: 'GET_MONITORING_FAIL',
  GET_MONITORING_CLEAN: 'GET_MONITORING_CLEAN',

  // GET DETOUR MONITORING
  GET_DETOUR_MONITORING_REQUEST: 'GET_DETOUR_MONITORING_REQUEST',
  GET_DETOUR_MONITORING_SUCCESS: 'GET_DETOUR_MONITORING_SUCCESS',
  GET_DETOUR_MONITORING_FAIL: 'GET_DETOUR_MONITORING_FAIL',
  GET_DETOUR_MONITORING_CLEAN: 'GET_DETOUR_MONITORING_CLEAN',

  // GET ALARM MONITORING
  GET_ALARM_MONITORING_REQUEST: 'GET_ALARM_MONITORING_REQUEST',
  GET_ALARM_MONITORING_SUCCESS: 'GET_ALARM_MONITORING_SUCCESS',
  GET_ALARM_MONITORING_FAIL: 'GET_ALARM_MONITORING_FAIL',
  GET_ALARM_MONITORING_CLEAN: 'GET_ALARM_MONITORING_CLEAN',

  // GET INSIGHT MONITORING
  GET_INSIGHT_MONITORING_REQUEST: 'GET_INSIGHT_MONITORING_REQUEST',
  GET_INSIGHT_MONITORING_SUCCESS: 'GET_INSIGHT_MONITORING_SUCCESS',
  GET_INSIGHT_MONITORING_FAIL: 'GET_INSIGHT_MONITORING_FAIL',
  GET_INSIGHT_MONITORING_CLEAN: 'GET_INSIGHT_MONITORING_CLEAN',

  // GET PLOTTING
  GET_PLOTTING_REQUEST: 'GET_PLOTTING_REQUEST',
  GET_PLOTTING_SUCCESS: 'GET_PLOTTING_SUCCESS',
  GET_PLOTTING_FAIL: 'GET_PLOTTING_FAIL',
  GET_PLOTTING_CLEAN: 'GET_PLOTTING_CLEAN',

  // GET MONITORING DASHBOARD
  GET_MONITORING_DASHBOARD_REQUEST: 'GET_MONITORING_DASHBOARD_REQUEST',
  GET_MONITORING_DASHBOARD_SUCCESS: 'GET_MONITORING_DASHBOARD_SUCCESS',
  GET_MONITORING_DASHBOARD_FAIL: 'GET_MONITORING_DASHBOARD_FAIL',
  GET_MONITORING_DASHBOARD_CLEAN: 'GET_MONITORING_DASHBOARD_CLEAN',
}

const INITIAL_STATE = {
  // GET MONITORING
  monitoringData: null,
  monitoringLoading: false,
  monitoringError: false,

  // GET DETOUR MONITORING
  detourMonitoringData: null,
  detourMonitoringLoading: false,
  detourMonitoringError: false,

  // GET ALARM MONITORING
  alarmMonitoringData: null,
  alarmMonitoringLoading: false,
  alarmMonitoringError: false,

  // GET INSIGHT MONITORING
  insightMonitoringData: [],
  insightMonitoringLoading: false,
  insightMonitoringError: false,

  // GET PLOTTING
  plottingData: null,
  plottingLoading: false,
  plottingError: false,

  // GET MONITORING DASHBOARD
  dashboard: null,
  dashboardLoading: false,
  dashboardError: false,
}

export default function VariableData(state = INITIAL_STATE, action) {
  switch (action.type) {
    // GET MONITORING
    case Types.GET_MONITORING_REQUEST:
      return {
        ...state,
        monitoringLoading: true,
        monitoringError: false,
      }
    case Types.GET_MONITORING_SUCCESS:
      return {
        ...state,
        monitoringLoading: false,
        monitoringError: false,
        monitoringData: action.payload,
      }
    case Types.GET_MONITORING_FAIL:
      return {
        ...state,
        monitoringLoading: false,
        monitoringError: true,
      }
    case Types.GET_MONITORING_CLEAN:
      return {
        ...state,
        monitoringLoading: false,
        monitoringError: false,
        monitoringData: null,
      }

    // GET DETOUR MONITORING
    case Types.GET_DETOUR_MONITORING_REQUEST:
      return {
        ...state,
        detourMonitoringLoading: true,
        detourMonitoringError: false,
      }
    case Types.GET_DETOUR_MONITORING_SUCCESS:
      return {
        ...state,
        detourMonitoringLoading: false,
        detourMonitoringError: false,
        detourMonitoringData: action.payload,
      }
    case Types.GET_DETOUR_MONITORING_FAIL:
      return {
        ...state,
        detourMonitoringLoading: false,
        detourMonitoringError: true,
      }
    case Types.GET_DETOUR_MONITORING_CLEAN:
      return {
        ...state,
        detourMonitoringLoading: false,
        detourMonitoringError: false,
        detourMonitoringData: null,
      }

    // GET ALARM MONITORING
    case Types.GET_ALARM_MONITORING_REQUEST:
      return {
        ...state,
        alarmMonitoringLoading: true,
        alarmMonitoringError: false,
      }
    case Types.GET_ALARM_MONITORING_SUCCESS:
      return {
        ...state,
        alarmMonitoringLoading: false,
        alarmMonitoringError: false,
        alarmMonitoringData: action.payload,
      }
    case Types.GET_ALARM_MONITORING_FAIL:
      return {
        ...state,
        alarmMonitoringLoading: false,
        alarmMonitoringError: true,
      }
    case Types.GET_ALARM_MONITORING_CLEAN:
      return {
        ...state,
        alarmMonitoringLoading: false,
        alarmMonitoringError: false,
        alarmMonitoringData: null,
      }

    // GET INSIGHT MONITORING
    case Types.GET_INSIGHT_MONITORING_REQUEST:
      return {
        ...state,
        insightMonitoringLoading: true,
        insightMonitoringError: false,
      }
    case Types.GET_INSIGHT_MONITORING_SUCCESS:
      return {
        ...state,
        insightMonitoringLoading: false,
        insightMonitoringError: false,
        insightMonitoringData: action.payload,
      }
    case Types.GET_INSIGHT_MONITORING_FAIL:
      return {
        ...state,
        insightMonitoringLoading: false,
        insightMonitoringError: true,
      }
    case Types.GET_INSIGHT_MONITORING_CLEAN:
      return {
        ...state,
        insightMonitoringLoading: false,
        insightMonitoringError: false,
        insightMonitoringData: [],
      }

    // GET PLOTTING
    case Types.GET_PLOTTING_REQUEST:
      return {
        ...state,
        plottingLoading: true,
        plottingError: false,
      }
    case Types.GET_PLOTTING_SUCCESS:
      return {
        ...state,
        plottingLoading: false,
        plottingError: false,
        plottingData: action.payload,
      }
    case Types.GET_PLOTTING_FAIL:
      return {
        ...state,
        plottingLoading: false,
        plottingError: true,
      }
    case Types.GET_PLOTTING_CLEAN:
      return {
        ...state,
        plottingLoading: false,
        plottingError: false,
        plottingData: null,
      }

    // GET MONITORING DASHBOARD
    case Types.GET_MONITORING_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboardLoading: true,
        dashboardError: false,
      }
    case Types.GET_MONITORING_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: false,
        dashboard: action.payload,
      }
    case Types.GET_MONITORING_DASHBOARD_FAIL:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: true,
      }
    case Types.GET_MONITORING_DASHBOARD_CLEAN:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: false,
        dashboard: null,
      }

    default:
      return state
  }
}

export const Creators = {
  // GET MONITORING
  getMonitoringRequest: payload => ({
    type: Types.GET_MONITORING_REQUEST,
    payload,
  }),
  getMonitoringSuccess: payload => ({
    type: Types.GET_MONITORING_SUCCESS,
    payload,
  }),
  getMonitoringFail: () => ({
    type: Types.GET_MONITORING_FAIL,
  }),
  getMonitoringClean: () => ({
    type: Types.GET_MONITORING_CLEAN,
  }),

  // GET DETOUR MONITORING
  getDetourMonitoringRequest: payload => ({
    type: Types.GET_DETOUR_MONITORING_REQUEST,
    payload,
  }),
  getDetourMonitoringSuccess: payload => ({
    type: Types.GET_DETOUR_MONITORING_SUCCESS,
    payload,
  }),
  getDetourMonitoringFail: () => ({
    type: Types.GET_DETOUR_MONITORING_FAIL,
  }),
  getDetourMonitoringClean: () => ({
    type: Types.GET_DETOUR_MONITORING_CLEAN,
  }),

  // GET ALARM MONITORING
  getAlarmMonitoringRequest: payload => ({
    type: Types.GET_ALARM_MONITORING_REQUEST,
    payload,
  }),
  getAlarmMonitoringSuccess: payload => ({
    type: Types.GET_ALARM_MONITORING_SUCCESS,
    payload,
  }),
  getAlarmMonitoringFail: () => ({
    type: Types.GET_ALARM_MONITORING_FAIL,
  }),
  getAlarmMonitoringClean: () => ({
    type: Types.GET_ALARM_MONITORING_CLEAN,
  }),

  // GET INSIGHT MONITORING
  getInsightMonitoringRequest: payload => ({
    type: Types.GET_INSIGHT_MONITORING_REQUEST,
    payload,
  }),
  getInsightMonitoringSuccess: payload => ({
    type: Types.GET_INSIGHT_MONITORING_SUCCESS,
    payload,
  }),
  getInsightMonitoringFail: () => ({
    type: Types.GET_INSIGHT_MONITORING_FAIL,
  }),
  getInsightMonitoringClean: () => ({
    type: Types.GET_INSIGHT_MONITORING_CLEAN,
  }),

  // GET PLOTTING
  getPlottingRequest: payload => ({
    type: Types.GET_PLOTTING_REQUEST,
    payload,
  }),
  getPlottingSuccess: payload => ({
    type: Types.GET_PLOTTING_SUCCESS,
    payload,
  }),
  getPlottingFail: () => ({
    type: Types.GET_PLOTTING_FAIL,
  }),
  getPlottingClean: () => ({
    type: Types.GET_PLOTTING_CLEAN,
  }),

  // GET MONITORING DASHBOARD
  getMonitoringDashboardRequest: payload => ({
    type: Types.GET_MONITORING_DASHBOARD_REQUEST,
    payload,
  }),
  getMonitoringDashboardSuccess: payload => ({
    type: Types.GET_MONITORING_DASHBOARD_SUCCESS,
    payload,
  }),
  getMonitoringDashboardFail: payload => ({
    type: Types.GET_MONITORING_DASHBOARD_FAIL,
    payload,
  }),
  getMonitoringDashboardClean: () => ({
    type: Types.GET_MONITORING_DASHBOARD_CLEAN,
  }),
}

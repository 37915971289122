import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  Types as ConnectionAlertTypes,
  Creators as ConnectionsAlertActions,
} from 'store/ducks/ConnectionAlert'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getConnectionsAlerts({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/connection/alerts', payload)
    if (status === 200) {
      yield put(ConnectionsAlertActions.getConnectionAlertsSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o alertas de conexão.')
      yield put(ConnectionsAlertActions.getConnectionAlertsFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o alertas de conexão.')
    yield put(ConnectionsAlertActions.getConnectionAlertsFail())
  }
}

function* showConnectionAlert({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/connection/alerts/${payload}`,
    )
    if (status === 200) {
      yield put(ConnectionsAlertActions.showConnectionAlertSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o alerta de conexão.')
      yield put(ConnectionsAlertActions.showConnectionAlertFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o alerta de conexão.')
    yield put(ConnectionsAlertActions.showConnectionAlertFail())
  }
}

function* updateStatusConnectionAlert({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/connection/alerts/${payload.id}/status`,
      payload,
    )
    if (status === 200) {
      yield put(ConnectionsAlertActions.updateStatusConnectionAlertSuccess())
    } else {
      parseError(data || 'Erro ae atualizar o status.')
      yield put(ConnectionsAlertActions.updateStatusConnectionAlertFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o status.')
    yield put(ConnectionsAlertActions.updateStatusConnectionAlertFail())
  }
}

function* getConnectionsAlertsWatcher() {
  yield takeLatest(
    ConnectionAlertTypes.GET_CONNECTION_ALERTS_REQUEST,
    getConnectionsAlerts,
  )
}

function* showConnectionAlertWatcher() {
  yield takeLatest(
    ConnectionAlertTypes.SHOW_CONNECTION_ALERT_REQUEST,
    showConnectionAlert,
  )
}

function* updateStatusConnectionAlertWatcher() {
  yield takeLatest(
    ConnectionAlertTypes.UPDATE_STATUS_CONNECTION_ALERT_REQUEST,
    updateStatusConnectionAlert,
  )
}

export default function* rootSaga() {
  yield all([
    fork(getConnectionsAlertsWatcher),
    fork(showConnectionAlertWatcher),
    fork(updateStatusConnectionAlertWatcher),
  ])
}

import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as yup from 'yup'
import { Creators as AuthActions } from 'store/ducks/Auth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from 'components/FormInput'
import LogoAiron from 'assets/images/logo-airon-symbol-branco.png'

const SignIn = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector(({ auth }) => auth)

  const schema = yup.object().shape({
    email: yup.string().email('Email inválido').required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório'),
  })

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  })

  const formInputs = [
    {
      label: 'Email',
      name: 'email',
      placeholder: 'Digite seu e-mail',
    },
    {
      label: 'Senha',
      name: 'password',
      placeholder: 'Digite sua senha',
      type: 'password',
    },
  ]

  const onSubmit = values => {
    dispatch(AuthActions.login(values))
  }

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Airon Software">
            <img
              src={LogoAiron}
              alt="Airon Software"
              title="Airon Software"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>Login</h1>
          </div>
          <div className="app-login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                {formInputs.map(item => (
                  <FormInput
                    key={item.name}
                    label={item.label}
                    name={item.name}
                    type={item.type}
                    placeholder={item.placeholder}
                    mask={item.mask}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                  />
                ))}
                <div className="d-flex align-items-center justify-content-center">
                  <Button variant="contained" color="primary" type="submit">
                    ENTRAR
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default SignIn

export const Types = {
  // GET LIST STATUS
  GET_STATUS_REQUEST: 'GET_STATUS_REQUEST',
  GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
  GET_STATUS_FAIL: 'GET_STATUS_FAIL',
  GET_STATUS_CLEAN: 'GET_STATUS_CLEAN',

  // CREATE STATUS
  POST_STATUS_REQUEST: 'POST_STATUS_REQUEST',
  POST_STATUS_SUCCESS: 'POST_STATUS_SUCCESS',
  POST_STATUS_FAIL: 'POST_STATUS_FAIL',
  POST_STATUS_CLEAN: 'POST_STATUS_CLEAN',
};

const INITIAL_STATUS = {
  data: null,
  loading: false,
  error: false,

  createLoading: false,
  createSuccess: false,
  createFail: false,
};

export default function Statuses(state = INITIAL_STATUS, action) {
  switch (action.type) {
    // GET LIST STATE
    case Types.GET_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Types.GET_STATUS_CLEAN:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
      };

    // CREATE STATUS
    case Types.POST_STATUS_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.POST_STATUS_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.POST_STATUS_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.POST_STATUS_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // LIST STATUS
  getStatusRequest: payload => ({
    type: Types.GET_STATUS_REQUEST,
    payload,
  }),
  getStatusSuccess: payload => ({
    type: Types.GET_STATUS_SUCCESS,
    payload,
  }),
  getStatusFail: () => ({
    type: Types.GET_STATUS_FAIL,
  }),
  getStatusClean: () => ({
    type: Types.GET_STATUS_CLEAN,
  }),

  // CREATE STATUS
  postStatusRequest: payload => ({
    type: Types.POST_STATUS_REQUEST,
    payload,
  }),
  postStatusSuccess: payload => ({
    type: Types.POST_STATUS_SUCCESS,
    payload,
  }),
  postStatusFail: () => ({
    type: Types.POST_STATUS_FAIL,
  }),
  postStatusClean: () => ({
    type: Types.POST_STATUS_CLEAN,
  }),
};

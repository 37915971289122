export const Types = {
  GET_GAS_LACK_REQUEST: 'GET_GAS_LACK_REQUEST',
  GET_GAS_LACK_SUCCESS: 'GET_GAS_LACK_SUCCESS',
  GET_GAS_LACK_FAIL: 'GET_GAS_LACK_FAIL',

  POST_GAS_LACK_REQUEST: 'POST_GAS_LACK_REQUEST',
  POST_GAS_LACK_SUCCESS: 'POST_GAS_LACK_SUCCESS',
  POST_GAS_LACK_FAIL: 'POST_GAS_LACK_FAIL',
  POST_GAS_LACK_CLEAN: 'POST_GAS_LACK_CLEAN',

  SHOW_GAS_LACK_REQUEST: 'SHOW_GAS_LACK_REQUEST',
  SHOW_GAS_LACK_SUCCESS: 'SHOW_GAS_LACK_SUCCESS',
  SHOW_GAS_LACK_FAIL: 'SHOW_GAS_LACK_FAIL',
  SHOW_GAS_LACK_CLEAN: 'SHOW_GAS_LACK_CLEAN',

  UPDATE_GAS_LACK_REQUEST: 'UPDATE_GAS_LACK_REQUEST',
  UPDATE_GAS_LACK_SUCCESS: 'UPDATE_GAS_LACK_SUCCESS',
  UPDATE_GAS_LACK_FAIL: 'UPDATE_GAS_LACK_FAIL',
  UPDATE_GAS_LACK_CLEAN: 'UPDATE_GAS_LACK_CLEAN',

  DELETE_GAS_LACK_REQUEST: 'DELETE_GAS_LACK_REQUEST',
  DELETE_GAS_LACK_SUCCESS: 'DELETE_GAS_LACK_SUCCESS',
  DELETE_GAS_LACK_FAIL: 'DELETE_GAS_LACK_FAIL',
  DELETE_GAS_LACK_CLEAN: 'DELETE_GAS_LACK_CLEAN',
}

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  showLoading: false,
  showError: false,
  showData: null,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,
}

export default function GasLack(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_GAS_LACK_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case Types.GET_GAS_LACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      }
    case Types.GET_GAS_LACK_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case Types.POST_GAS_LACK_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      }
    case Types.POST_GAS_LACK_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      }
    case Types.POST_GAS_LACK_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      }
    case Types.POST_GAS_LACK_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      }

    case Types.SHOW_GAS_LACK_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case Types.SHOW_GAS_LACK_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      }
    case Types.SHOW_GAS_LACK_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      }
    case Types.SHOW_GAS_LACK_CLEAN:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: null,
      }

    case Types.UPDATE_GAS_LACK_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      }
    case Types.UPDATE_GAS_LACK_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      }
    case Types.UPDATE_GAS_LACK_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      }
    case Types.UPDATE_GAS_LACK_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      }

    case Types.DELETE_GAS_LACK_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      }
    case Types.DELETE_GAS_LACK_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      }
    case Types.DELETE_GAS_LACK_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      }
    case Types.DELETE_GAS_LACK_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      }

    default:
      return state
  }
}

export const Creators = {
  // GET
  getGasLackRequest: payload => ({
    type: Types.GET_GAS_LACK_REQUEST,
    payload,
  }),
  getGasLackSuccess: payload => ({
    type: Types.GET_GAS_LACK_SUCCESS,
    payload,
  }),
  getGasLackFail: () => ({
    type: Types.GET_GAS_LACK_FAIL,
  }),

  // POST
  postGasLackRequest: payload => ({
    type: Types.POST_GAS_LACK_REQUEST,
    payload,
  }),
  postGasLackSuccess: payload => ({
    type: Types.POST_GAS_LACK_SUCCESS,
    payload,
  }),
  postGasLackFail: () => ({
    type: Types.POST_GAS_LACK_FAIL,
  }),
  postGasLackClean: () => ({
    type: Types.POST_GAS_LACK_CLEAN,
  }),

  // SHOW
  showGasLackRequest: payload => ({
    type: Types.SHOW_GAS_LACK_REQUEST,
    payload,
  }),
  showGasLackSuccess: payload => ({
    type: Types.SHOW_GAS_LACK_SUCCESS,
    payload,
  }),
  showGasLackFail: () => ({
    type: Types.SHOW_GAS_LACK_FAIL,
  }),
  showGasLackClean: () => ({
    type: Types.SHOW_GAS_LACK_CLEAN,
  }),

  // UPDATE
  updateGasLackRequest: payload => ({
    type: Types.UPDATE_GAS_LACK_REQUEST,
    payload,
  }),
  updateGasLackSuccess: payload => ({
    type: Types.UPDATE_GAS_LACK_SUCCESS,
    payload,
  }),
  updateGasLackFail: () => ({
    type: Types.UPDATE_GAS_LACK_FAIL,
  }),
  updateGasLackClean: () => ({
    type: Types.UPDATE_GAS_LACK_CLEAN,
  }),

  // DELETE
  deleteGasLackRequest: payload => ({
    type: Types.DELETE_GAS_LACK_REQUEST,
    payload,
  }),
  deleteGasLackSuccess: payload => ({
    type: Types.DELETE_GAS_LACK_SUCCESS,
    payload,
  }),
  deleteGasLackFail: () => ({
    type: Types.DELETE_GAS_LACK_FAIL,
  }),
  deleteGasLackClean: () => ({
    type: Types.DELETE_GAS_LACK_CLEAN,
  }),
}

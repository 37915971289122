export const Types = {
  GET_MOLD_REQUEST: 'GET_MOLD_REQUEST',
  GET_MOLD_SUCCESS: 'GET_MOLD_SUCCESS',
  GET_MOLD_FAIL: 'GET_MOLD_FAIL',

  POST_MOLD_REQUEST: 'POST_MOLD_REQUEST',
  POST_MOLD_SUCCESS: 'POST_MOLD_SUCCESS',
  POST_MOLD_FAIL: 'POST_MOLD_FAIL',
  POST_MOLD_CLEAN: 'POST_MOLD_CLEAN',

  SHOW_MOLD_REQUEST: 'SHOW_MOLD_REQUEST',
  SHOW_MOLD_SUCCESS: 'SHOW_MOLD_SUCCESS',
  SHOW_MOLD_FAIL: 'SHOW_MOLD_FAIL',
  SHOW_MOLD_CLEAN: 'SHOW_MOLD_CLEAN',

  UPDATE_MOLD_REQUEST: 'UPDATE_MOLD_REQUEST',
  UPDATE_MOLD_SUCCESS: 'UPDATE_MOLD_SUCCESS',
  UPDATE_MOLD_FAIL: 'UPDATE_MOLD_FAIL',
  UPDATE_MOLD_CLEAN: 'UPDATE_MOLD_CLEAN',

  DELETE_MOLD_REQUEST: 'DELETE_MOLD_REQUEST',
  DELETE_MOLD_SUCCESS: 'DELETE_MOLD_SUCCESS',
  DELETE_MOLD_FAIL: 'DELETE_MOLD_FAIL',
  DELETE_MOLD_CLEAN: 'DELETE_MOLD_CLEAN',

  ADD_VARIABLE_MOLD_REQUEST: 'ADD_VARIABLE_MOLD_REQUEST',
  ADD_VARIABLE_MOLD_SUCCESS: 'ADD_VARIABLE_MOLD_SUCCESS',
  ADD_VARIABLE_MOLD_FAIL: 'ADD_VARIABLE_MOLD_FAIL',
  ADD_VARIABLE_MOLD_CLEAN: 'ADD_VARIABLE_MOLD_CLEAN',

  DELETE_VARIABLE_MOLD_REQUEST: 'DELETE_VARIABLE_MOLD_REQUEST',
  DELETE_VARIABLE_MOLD_SUCCESS: 'DELETE_VARIABLE_MOLD_SUCCESS',
  DELETE_VARIABLE_MOLD_FAIL: 'DELETE_VARIABLE_MOLD_FAIL',
  DELETE_VARIABLE_MOLD_CLEAN: 'DELETE_VARIABLE_MOLD_CLEAN',

  UPDATE_VARIABLE_MOLD_REQUEST: 'UPDATE_VARIABLE_MOLD_REQUEST',
  UPDATE_VARIABLE_MOLD_SUCCESS: 'UPDATE_VARIABLE_MOLD_SUCCESS',
  UPDATE_VARIABLE_MOLD_FAIL: 'UPDATE_VARIABLE_MOLD_FAIL',
  UPDATE_VARIABLE_MOLD_CLEAN: 'UPDATE_VARIABLE_MOLD_CLEAN',

  GET_ALL_MOLDS_REQUEST: 'GET_ALL_MOLDS_REQUEST',
  GET_ALL_MOLDS_SUCCESS: 'GET_ALL_MOLDS_SUCCESS',
  GET_ALL_MOLDS_FAIL: 'GET_ALL_MOLDS_FAIL',
  GET_ALL_MOLDS_CLEAN: 'GET_ALL_MOLDS_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  showData: null,
  showLoading: false,
  showError: false,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  addVarLoading: false,
  addVarSuccess: false,
  addVarFail: false,

  deleteVarLoading: false,
  deleteVarSuccess: false,
  deleteVarFail: false,

  updateVarLoading: false,
  updateVarSuccess: false,
  updateVarFail: false,

  dataAll: null,
  loadingAll: false,
  errorAll: false,
};

export default function Mold(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_MOLD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_MOLD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_MOLD_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.POST_MOLD_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      };
    case Types.POST_MOLD_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      };
    case Types.POST_MOLD_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      };
    case Types.POST_MOLD_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      };

    case Types.SHOW_MOLD_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      };
    case Types.SHOW_MOLD_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      };
    case Types.SHOW_MOLD_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      };
    case Types.SHOW_MOLD_CLEAN:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: null,
      };

    case Types.UPDATE_MOLD_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_MOLD_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_MOLD_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_MOLD_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    case Types.DELETE_MOLD_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_MOLD_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_MOLD_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_MOLD_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.ADD_VARIABLE_MOLD_REQUEST:
      return {
        ...state,
        addVarLoading: true,
        addVarSuccess: false,
        addVarFail: false,
      };
    case Types.ADD_VARIABLE_MOLD_SUCCESS:
      return {
        ...state,
        addVarLoading: false,
        addVarSuccess: true,
        addVarFail: false,
      };
    case Types.ADD_VARIABLE_MOLD_FAIL:
      return {
        ...state,
        addVarLoading: false,
        addVarSuccess: false,
        addVarFail: true,
      };
    case Types.ADD_VARIABLE_MOLD_CLEAN:
      return {
        ...state,
        addVarLoading: false,
        addVarSuccess: false,
        addVarFail: false,
      };

    case Types.DELETE_VARIABLE_MOLD_REQUEST:
      return {
        ...state,
        deleteVarLoading: true,
        deleteVarSuccess: false,
        deleteVarFail: false,
      };
    case Types.DELETE_VARIABLE_MOLD_SUCCESS:
      return {
        ...state,
        deleteVarLoading: false,
        deleteVarSuccess: true,
        deleteVarFail: false,
      };
    case Types.DELETE_VARIABLE_MOLD_FAIL:
      return {
        ...state,
        deleteVarLoading: false,
        deleteVarSuccess: false,
        deleteVarFail: true,
      };
    case Types.DELETE_VARIABLE_MOLD_CLEAN:
      return {
        ...state,
        deleteVarLoading: false,
        deleteVarSuccess: false,
        deleteVarFail: false,
      };

    case Types.UPDATE_VARIABLE_MOLD_REQUEST:
      return {
        ...state,
        updateVarLoading: true,
        updateVarSuccess: false,
        updateVarFail: false,
      };
    case Types.UPDATE_VARIABLE_MOLD_SUCCESS:
      return {
        ...state,
        updateVarLoading: false,
        updateVarSuccess: true,
        updateVarFail: false,
      };
    case Types.UPDATE_VARIABLE_MOLD_FAIL:
      return {
        ...state,
        updateVarLoading: false,
        updateVarSuccess: false,
        updateVarFail: true,
      };
    case Types.UPDATE_VARIABLE_MOLD_CLEAN:
      return {
        ...state,
        updateVarLoading: false,
        updateVarSuccess: false,
        updateVarFail: false,
      };

    case Types.GET_ALL_MOLDS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        errorAll: false,
      };
    case Types.GET_ALL_MOLDS_SUCCESS:
      return {
        ...state,
        loadingAll: false,
        errorAll: false,
        dataAll: action.payload,
      };
    case Types.GET_ALL_MOLDS_FAIL:
      return {
        ...state,
        loadingAll: false,
        errorAll: true,
      };
    case Types.GET_ALL_MOLDS_CLEAN:
      return {
        ...state,
        loadingAll: false,
        errorAll: false,
        dataAll: null,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getMoldRequest: payload => ({
    type: Types.GET_MOLD_REQUEST,
    payload,
  }),
  getMoldSuccess: payload => ({
    type: Types.GET_MOLD_SUCCESS,
    payload,
  }),
  getMoldFail: () => ({
    type: Types.GET_MOLD_FAIL,
  }),

  // POST
  postMoldRequest: payload => ({
    type: Types.POST_MOLD_REQUEST,
    payload,
  }),
  postMoldSuccess: payload => ({
    type: Types.POST_MOLD_SUCCESS,
    payload,
  }),
  postMoldFail: () => ({
    type: Types.POST_MOLD_FAIL,
  }),
  postMoldClean: () => ({
    type: Types.POST_MOLD_CLEAN,
  }),

  // SHOW
  showMoldRequest: payload => ({
    type: Types.SHOW_MOLD_REQUEST,
    payload,
  }),
  showMoldSuccess: payload => ({
    type: Types.SHOW_MOLD_SUCCESS,
    payload,
  }),
  showMoldFail: () => ({
    type: Types.SHOW_MOLD_FAIL,
  }),
  showMoldClean: () => ({
    type: Types.SHOW_MOLD_CLEAN,
  }),

  // UPDATE
  updateMoldRequest: payload => ({
    type: Types.UPDATE_MOLD_REQUEST,
    payload,
  }),
  updateMoldSuccess: payload => ({
    type: Types.UPDATE_MOLD_SUCCESS,
    payload,
  }),
  updateMoldFail: () => ({
    type: Types.UPDATE_MOLD_FAIL,
  }),
  updateMoldClean: () => ({
    type: Types.UPDATE_MOLD_CLEAN,
  }),

  // DELETE
  delteMoldRequest: payload => ({
    type: Types.DELETE_MOLD_REQUEST,
    payload,
  }),
  deleteMoldSuccess: payload => ({
    type: Types.DELETE_MOLD_SUCCESS,
    payload,
  }),
  deleteMoldFail: () => ({
    type: Types.DELETE_MOLD_FAIL,
  }),
  deleteMoldClean: () => ({
    type: Types.DELETE_MOLD_CLEAN,
  }),

  // ADD VARIABLE
  addVariableRequest: payload => ({
    type: Types.ADD_VARIABLE_MOLD_REQUEST,
    payload,
  }),
  addVariableSuccess: payload => ({
    type: Types.ADD_VARIABLE_MOLD_SUCCESS,
    payload,
  }),
  addVariableFail: () => ({
    type: Types.ADD_VARIABLE_MOLD_FAIL,
  }),
  addVariableClean: () => ({
    type: Types.ADD_VARIABLE_MOLD_CLEAN,
  }),

  // DELETE VARIABLE
  deleteVariableRequest: payload => ({
    type: Types.DELETE_VARIABLE_MOLD_REQUEST,
    payload,
  }),
  deleteVariableSuccess: payload => ({
    type: Types.DELETE_VARIABLE_MOLD_SUCCESS,
    payload,
  }),
  deleteVariableFail: () => ({
    type: Types.DELETE_VARIABLE_MOLD_FAIL,
  }),
  deleteVariableClean: () => ({
    type: Types.DELETE_VARIABLE_MOLD_CLEAN,
  }),

  // UPDATE VARIABLE
  updateVariableRequest: payload => ({
    type: Types.UPDATE_VARIABLE_MOLD_REQUEST,
    payload,
  }),
  updateVariableSuccess: payload => ({
    type: Types.UPDATE_VARIABLE_MOLD_SUCCESS,
    payload,
  }),
  updateVariableFail: () => ({
    type: Types.UPDATE_VARIABLE_MOLD_FAIL,
  }),
  updateVariableClean: () => ({
    type: Types.UPDATE_VARIABLE_MOLD_CLEAN,
  }),

  // GET ALL MOLDS
  getAllMoldsRequest: payload => ({
    type: Types.GET_ALL_MOLDS_REQUEST,
    payload,
  }),
  getAllMoldsSuccess: payload => ({
    type: Types.GET_ALL_MOLDS_SUCCESS,
    payload,
  }),
  getAllMoldsFail: () => ({
    type: Types.GET_ALL_MOLDS_FAIL,
  }),
  getAllMoldsClean: () => ({
    type: Types.GET_ALL_MOLDS_CLEAN,
  }),
};

import { NotificationManager } from 'react-notifications';
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import {
  Types as IntersectionTypes,
  Creators as IntersectionActions,
} from 'store/ducks/Intersection';
import api from 'util/Api';
import { parseError } from 'util/index';

function* getIntersection({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/intersections', payload);
    if (status === 200) {
      yield put(IntersectionActions.getIntersectionSuccess(data));
    } else {
      parseError(data || 'Falha ao listar interseção.');
      yield put(IntersectionActions.getIntersectionFail(data));
    }
  } catch (err) {
    parseError(err || 'Falha ao listar interseção.');
    yield put(IntersectionActions.getIntersectionFail());
  }
}

function* getIntersectionWatcher() {
  yield takeLatest(IntersectionTypes.GET_INTERSECTION_REQUEST, getIntersection);
}

export default function* rootSaga() {
  yield all([fork(getIntersectionWatcher)]);
}

export const Types = {
  LIST_EQUIPAMENTS_REQUEST: 'LIST_EQUIPAMENTS_REQUEST',
  LIST_EQUIPAMENTS_SUCCESS: 'LIST_EQUIPAMENTS_SUCCESS',
  LIST_EQUIPAMENTS_ERROR: 'LIST_EQUIPAMENTS_ERROR',

  GET_EQUIPAMENT_ID_REQUEST: 'GET_EQUIPAMENT_ID_REQUEST',
  GET_EQUIPAMENT_ID_SUCCESS: 'GET_EQUIPAMENT_ID_SUCCESS',
  GET_EQUIPAMENT_ID_ERROR: 'GET_EQUIPAMENT_ID_ERROR',
  GET_EQUIPAMENT_ID_CLEAN: 'GET_EQUIPAMENT_ID_CLEAN',

  CREATE_EQUIPAMENT_REQUEST: 'CREATE_EQUIPAMENT_REQUEST',
  CREATE_EQUIPAMENT_SUCCESS: 'CREATE_EQUIPAMENT_SUCCESS',
  CREATE_EQUIPAMENT_ERROR: 'CREATE_EQUIPAMENT_ERROR',
  CREATE_RESET: 'CREATE_RESET',

  EDIT_EQUIPAMENT_REQUEST: 'EDIT_EQUIPAMENT_REQUEST',
  EDIT_EQUIPAMENT_SUCCESS: 'EDIT_EQUIPAMENT_SUCCESS',
  EDIT_EQUIPAMENT_ERROR: 'EDIT_EQUIPAMENT_ERROR',
  EDIT_EQUIPAMENT_CLEAN: 'EDIT_EQUIPAMENT_CLEAN',

  DELETE_EQUIPAMENT_REQUEST: 'DELETE_EQUIPAMENT_REQUEST',
  DELETE_EQUIPAMENT_SUCCESS: 'DELETE_EQUIPAMENT_SUCCESS',
  DELETE_EQUIPAMENT_ERROR: 'DELETE_EQUIPAMENT_ERROR',
  DELETE_EQUIPAMENT_CLEAN: 'DELETE_EQUIPAMENT_CLEAN',

  IMPORT_VARIABLE_EQUIPMENT_REQUEST: 'IMPORT_VARIABLE_EQUIPMENT_REQUEST',
  IMPORT_VARIABLE_EQUIPMENT_SUCCESS: 'IMPORT_VARIABLE_EQUIPMENT_SUCCESS',
  IMPORT_VARIABLE_EQUIPMENT_FAIL: 'IMPORT_VARIABLE_EQUIPMENT_FAIL',
  IMPORT_VARIABLE_EQUIPMENT_CLEAN: 'IMPORT_VARIABLE_EQUIPMENT_CLEAN',

  GET_DASHBOARD_EQUIPMENT_REQUEST: 'GET_DASHBOARD_EQUIPMENT_REQUEST',
  GET_DASHBOARD_EQUIPMENT_SUCCESS: 'GET_DASHBOARD_EQUIPMENT_SUCCESS',
  GET_DASHBOARD_EQUIPMENT_FAIL: 'GET_DASHBOARD_EQUIPMENT_FAIL',
  GET_DASHBOARD_EQUIPMENT_CLEAN: 'GET_DASHBOARD_EQUIPMENT_CLEAN',

  GET_HAS_ICON_EQUIPMENT_REQUEST: 'GET_HAS_ICON_EQUIPMENT_REQUEST',
  GET_HAS_ICON_EQUIPMENT_SUCCESS: 'GET_HAS_ICON_EQUIPMENT_SUCCESS',
  GET_HAS_ICON_EQUIPMENT_FAIL: 'GET_HAS_ICON_EQUIPMENT_FAIL',
  GET_HAS_ICON_EQUIPMENT_CLEAN: 'GET_HAS_ICON_EQUIPMENT_CLEAN',

  FORCE_READ_EQUIPMENT_REQUEST: 'FORCE_READ_EQUIPMENT_REQUEST',
  FORCE_READ_EQUIPMENT_SUCCESS: 'FORCE_READ_EQUIPMENT_SUCCESS',
  FORCE_READ_EQUIPMENT_FAIL: 'FORCE_READ_EQUIPMENT_FAIL',
}

const INIT_STATE = {
  data: [],
  loading: false,
  success: false,
  error: false,

  createData: [],
  createLoading: false,
  createSuccess: false,
  createError: false,

  dataId: [],
  loadingId: false,
  successId: false,
  errorId: false,

  importLoading: false,
  importSuccess: false,
  importError: false,
  importMessages: null,

  dashboard: null,
  dashboardLoading: false,
  dashboardError: false,

  hasIconData: null,
  hasIconLoading: false,
  hasIconError: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  forceReadLoading: false,
  forceReadSuccess: false,
  forceReadError: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_EQUIPAMENT_REQUEST:
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createError: false,
      }
    case Types.CREATE_EQUIPAMENT_SUCCESS:
      return {
        ...state,
        createData: action.payload,
        createLoading: false,
        createSuccess: true,
        createError: false,
      }
    case Types.CREATE_EQUIPAMENT_ERROR:
      return {
        ...state,
        createLoading: false,
        createSuccess: false,
        createError: true,
      }
    case Types.CREATE_RESET:
      return {
        ...state,
        createSuccess: false,
      }
    case Types.LIST_EQUIPAMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    case Types.LIST_EQUIPAMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
      }
    case Types.LIST_EQUIPAMENTS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      }
    case Types.GET_EQUIPAMENT_ID_REQUEST:
      return {
        ...state,
        loadingId: true,
        successId: false,
        errorIdId: false,
      }
    case Types.GET_EQUIPAMENT_ID_SUCCESS:
      return {
        ...state,
        dataId: action.payload,
        loadingId: false,
        successId: true,
        errorId: false,
      }
    case Types.GET_EQUIPAMENT_ID_ERROR:
      return {
        ...state,
        loadingId: false,
        successId: false,
        errorId: true,
      }
    case Types.GET_EQUIPAMENT_ID_CLEAN:
      return {
        ...state,
        loadingId: false,
        successId: false,
        errorId: false,
        dataId: [],
      }

    case Types.EDIT_EQUIPAMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    case Types.EDIT_EQUIPAMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      }
    case Types.EDIT_EQUIPAMENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      }
    case Types.EDIT_EQUIPAMENT_CLEAN: {
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      }
    }

    case Types.DELETE_EQUIPAMENT_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      }
    case Types.DELETE_EQUIPAMENT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      }
    case Types.DELETE_EQUIPAMENT_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      }
    case Types.DELETE_EQUIPAMENT_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      }

    case Types.IMPORT_VARIABLE_EQUIPMENT_REQUEST:
      return {
        ...state,
        importLoading: true,
        importSuccess: false,
        importError: false,
      }
    case Types.IMPORT_VARIABLE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        importLoading: false,
        importSuccess: true,
        importError: false,
      }
    case Types.IMPORT_VARIABLE_EQUIPMENT_FAIL:
      return {
        ...state,
        importLoading: false,
        importSuccess: false,
        importError: true,
        importMessages: action.payload,
      }
    case Types.IMPORT_VARIABLE_EQUIPMENT_CLEAN:
      return {
        ...state,
        importLoading: false,
        importSuccess: false,
        importError: false,
        importMessages: null,
      }

    // DASHBOARD
    case Types.GET_DASHBOARD_EQUIPMENT_REQUEST:
      return {
        ...state,
        dashboardLoading: true,
        dashboardError: false,
      }
    case Types.GET_DASHBOARD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: false,
        dashboard: action.payload,
      }
    case Types.GET_DASHBOARD_EQUIPMENT_FAIL:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: true,
      }
    case Types.GET_DASHBOARD_EQUIPMENT_CLEAN:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: false,
        dashboard: null,
      }

    // GET HAS ICON
    case Types.GET_HAS_ICON_EQUIPMENT_REQUEST:
      return {
        ...state,
        hasIconLoading: true,
        hasIconError: false,
      }
    case Types.GET_HAS_ICON_EQUIPMENT_SUCCESS:
      return {
        ...state,
        hasIconLoading: false,
        hasIconError: false,
        hasIconData: action.payload,
      }
    case Types.GET_HAS_ICON_EQUIPMENT_FAIL:
      return {
        ...state,
        hasIconLoading: false,
        hasIconError: true,
      }
    case Types.GET_HAS_ICON_EQUIPMENT_CLEAN:
      return {
        ...state,
        hasIconLoading: false,
        hasIconError: false,
        hasIconData: null,
      }

    case Types.FORCE_READ_EQUIPMENT_REQUEST:
      return {
        ...state,
        forceReadLoading: true,
        forceReadSuccess: false,
        forceReadError: false,
      }
    case Types.FORCE_READ_EQUIPMENT_SUCCESS:
      return {
        ...state,
        forceReadLoading: false,
        forceReadSuccess: true,
        forceReadError: false,
      }
    case Types.FORCE_READ_EQUIPMENT_FAIL:
      return {
        ...state,
        forceReadLoading: false,
        forceReadSuccess: false,
        forceReadError: true,
      }

    default:
      return state
  }
}

export const Creators = {
  // CREATE
  createEquipamentRequest: payload => ({
    type: Types.CREATE_EQUIPAMENT_REQUEST,
    payload,
  }),
  createEquipamentSuccess: payload => ({
    type: Types.CREATE_EQUIPAMENT_SUCCESS,
    payload,
  }),
  createEquipamentError: () => ({
    type: Types.CREATE_EQUIPAMENT_ERROR,
  }),
  createReset: () => ({
    type: Types.CREATE_RESET,
  }),

  // LIST
  listEquipamentRequest: filters => ({
    type: Types.LIST_EQUIPAMENTS_REQUEST,
    payload: filters,
  }),
  listEquipamentSuccess: payload => ({
    type: Types.LIST_EQUIPAMENTS_SUCCESS,
    payload,
  }),
  listEquipamentError: () => ({
    type: Types.LIST_EQUIPAMENTS_ERROR,
  }),

  // SHOW
  getEquipamentIdRequest: id => ({
    type: Types.GET_EQUIPAMENT_ID_REQUEST,
    payload: id,
  }),
  getEquipamentIdSuccess: payload => ({
    type: Types.GET_EQUIPAMENT_ID_SUCCESS,
    payload,
  }),
  getEquipamentIdError: () => ({
    type: Types.GET_EQUIPAMENT_ID_ERROR,
  }),
  getEquipamentIdClean: () => ({
    type: Types.GET_EQUIPAMENT_ID_CLEAN,
  }),

  // UPDATE
  editEquipamentRequest: payload => ({
    type: Types.EDIT_EQUIPAMENT_REQUEST,
    payload,
  }),
  editEquipamentSuccess: () => ({
    type: Types.EDIT_EQUIPAMENT_SUCCESS,
  }),
  editEquipamentError: () => ({
    type: Types.EDIT_EQUIPAMENT_ERROR,
  }),
  editEquipamentClean: () => ({
    type: Types.EDIT_EQUIPAMENT_CLEAN,
  }),

  // DELETE
  deleteEquipamentRequest: id => ({
    type: Types.DELETE_EQUIPAMENT_REQUEST,
    payload: id,
  }),
  deleteEquipamentSuccess: () => ({
    type: Types.DELETE_EQUIPAMENT_SUCCESS,
  }),
  deleteEquipamentError: () => ({
    type: Types.DELETE_EQUIPAMENT_ERROR,
  }),
  deleteEquipamentClean: () => ({
    type: Types.DELETE_EQUIPAMENT_CLEAN,
  }),

  // IMPORT VARIABLE
  importVariableEquipmentRequest: payload => ({
    type: Types.IMPORT_VARIABLE_EQUIPMENT_REQUEST,
    payload,
  }),
  importVariableEquipmentSuccess: payload => ({
    type: Types.IMPORT_VARIABLE_EQUIPMENT_SUCCESS,
    payload,
  }),
  importVariableEquipmentFail: payload => ({
    type: Types.IMPORT_VARIABLE_EQUIPMENT_FAIL,
    payload,
  }),
  importVariableEquipmentClean: () => ({
    type: Types.IMPORT_VARIABLE_EQUIPMENT_CLEAN,
  }),

  // DASHBOARD
  getDashboardEquipmentRequest: payload => ({
    type: Types.GET_DASHBOARD_EQUIPMENT_REQUEST,
    payload,
  }),
  getDashBoardEquipmentSuccess: payload => ({
    type: Types.GET_DASHBOARD_EQUIPMENT_SUCCESS,
    payload,
  }),
  getDashboardEquipmentFail: payload => ({
    type: Types.GET_DASHBOARD_EQUIPMENT_FAIL,
    payload,
  }),
  getDashboardEquipmentClean: () => ({
    type: Types.GET_DASHBOARD_EQUIPMENT_CLEAN,
  }),

  // GET HAS ICON
  getHasIconEquipmentRequest: payload => ({
    type: Types.GET_HAS_ICON_EQUIPMENT_REQUEST,
    payload,
  }),
  getHasIconEquipmentSuccess: payload => ({
    type: Types.GET_HAS_ICON_EQUIPMENT_SUCCESS,
    payload,
  }),
  getHasIconEquipmentFail: () => ({
    type: Types.GET_HAS_ICON_EQUIPMENT_FAIL,
  }),
  getHasIconEquipmentClean: () => ({
    type: Types.GET_HAS_ICON_EQUIPMENT_CLEAN,
  }),

  forceReadEquipmentRequest: id => ({
    type: Types.FORCE_READ_EQUIPMENT_REQUEST,
    payload: id,
  }),
  forceReadEquipmentSuccess: () => ({
    type: Types.FORCE_READ_EQUIPMENT_SUCCESS,
  }),
  forceReadEquipmentError: () => ({
    type: Types.FORCE_READ_EQUIPMENT_FAIL,
  }),
}

export const Types = {
  // GET
  GET_VARIABLE_DASHBOARD_REQUEST: 'GET_VARIABLE_DASHBOARD_REQUEST',
  GET_VARIABLE_DASHBOARD_SUCCESS: 'GET_VARIABLE_DASHBOARD_SUCCESS',
  GET_VARIABLE_DASHBOARD_FAIL: 'GET_VARIABLE_DASHBOARD_FAIL',
  GET_VARIABLE_DASHBOARD_CLEAN: 'GET_VARIABLE_DASHBOARD_CLEAN',

  // POST
  POST_VARIABLE_DASHBOARD_REQUEST: 'POST_VARIABLE_DASHBOARD_REQUEST',
  POST_VARIABLE_DASHBOARD_SUCCESS: 'POST_VARIABLE_DASHBOARD_SUCCESS',
  POST_VARIABLE_DASHBOARD_FAIL: 'POST_VARIABLE_DASHBOARD_FAIL',
  POST_VARIABLE_DASHBOARD_CLEAN: 'POST_VARIABLE_DASHBOARD_CLEAN',
};

const INITIAL_STATE = {
  // GET
  data: null,
  loading: false,
  error: false,

  // POST
  postLoading: false,
  postError: false,
  postSuccess: false,
};

export default function dashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    // GET
    case Types.GET_VARIABLE_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_VARIABLE_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_VARIABLE_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Types.GET_VARIABLE_DASHBOARD_CLEAN:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
      };

    // POST
    case Types.POST_VARIABLE_DASHBOARD_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      };
    case Types.POST_VARIABLE_DASHBOARD_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      };
    case Types.POST_VARIABLE_DASHBOARD_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      };
    case Types.POST_VARIABLE_DASHBOARD_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  // GET
  getVariableDashboardRequest: payload => ({
    type: Types.GET_VARIABLE_DASHBOARD_REQUEST,
    payload,
  }),
  getVariableDashboardSuccess: payload => ({
    type: Types.GET_VARIABLE_DASHBOARD_SUCCESS,
    payload,
  }),
  getVariableDashboardFail: payload => ({
    type: Types.GET_VARIABLE_DASHBOARD_FAIL,
    payload,
  }),
  getVariableDashboardClean: () => ({
    type: Types.GET_VARIABLE_DASHBOARD_CLEAN,
  }),

  // POST
  postVariableDashboardRequest: payload => ({
    type: Types.GET_VARIABLE_DASHBOARD_REQUEST,
    payload,
  }),
  postVariableDashboardSuccess: payload => ({
    type: Types.POST_VARIABLE_DASHBOARD_SUCCESS,
    payload,
  }),
  postVariableDashboardFail: payload => ({
    type: Types.POST_VARIABLE_DASHBOARD_FAIL,
    payload,
  }),
  postVariableDashboardClean: () => ({
    type: Types.POST_VARIABLE_DASHBOARD_CLEAN,
  }),
};

export const Types = {
   // GET SUBSCRIPTION OVERVIEW
   GET_SUBSCRIPTION_OVERVIEW_REQUEST: 'GET_SUBSCRIPTION_OVERVIEW_REQUEST',
   GET_SUBSCRIPTION_OVERVIEW_SUCCESS: 'GET_SUBSCRIPTION_OVERVIEW_SUCCESS',
   GET_SUBSCRIPTION_OVERVIEW_FAIL: 'GET_SUBSCRIPTION_OVERVIEW_FAIL',
   GET_SUBSCRIPTION_OVERVIEW_CLEAN: 'GET_SUBSCRIPTION_OVERVIEW_CLEAN',
}

const INITIAL_STATE = {
   data: null,
   loading: false,
   error: false,
}

export default function subscription(state = INITIAL_STATE, action) {
   switch (action.type) {
      // GET SUBSCRIPTION OVERVIEW
      case Types.GET_SUBSCRIPTION_OVERVIEW_REQUEST:
         return {
            ...state,
            loading: true,
            error: false,
         }
      case Types.GET_SUBSCRIPTION_OVERVIEW_SUCCESS:
         return {
            ...state,
            loading: false,
            error: false,
            data: action.payload,
         }
      case Types.GET_SUBSCRIPTION_OVERVIEW_FAIL:
         return {
            ...state,
            loading: false,
            error: true,
         }
      case Types.GET_SUBSCRIPTION_OVERVIEW_CLEAN:
         return {
            ...state,
            loading: false,
            error: false,
            data: null,
         }

      default:
         return state
   }
}

export const Creators = {
   // GET INTERSECTION
   getSubscriptionOverviewRequest: payload => ({
      type: Types.GET_SUBSCRIPTION_OVERVIEW_REQUEST,
      payload,
   }),
   getSubscriptionOverviewSuccess: payload => ({
      type: Types.GET_SUBSCRIPTION_OVERVIEW_SUCCESS,
      payload,
   }),
   getSubscriptionOverviewFail: payload => ({
      type: Types.GET_SUBSCRIPTION_OVERVIEW_FAIL,
      payload,
   }),
   getSubscriptionOverviewClean: () => ({
      type: Types.GET_SUBSCRIPTION_OVERVIEW_CLEAN,
   }),
}

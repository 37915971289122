export const Types = {
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  INIT_URL: 'INIT_URL',
  ON_HIDE_LOADER: 'ON_HIDE_LOADER',
  ON_SHOW_LOADER: 'ON_SHOW_LOADER',
  SHOW_MESSAGE: 'SHOW_MESSAGE',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOOGIN_ERROR',
  LOGIN_RESET: 'LOGIN_RESET',
  SET_TYPE_USER: 'SET_TYPE_USER',

  CLEAN_AUTH_USER: 'CLEAN_AUTH_USER',
};

const INIT_STATE = {
  loading: false,
  roles: [],
  isLogged: false,
  data: null,
  initURL: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    case Types.LOGIN:
      return { ...state, loading: true, success: false };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        success: true,
      };
    case Types.LOGIN_ERROR:
      return { ...state, loading: false };
    case Types.LOGIN_RESET:
      return { ...state, loading: false, success: false };
    case Types.CLEAN_AUTH_USER:
      return { ...state, data: null };
    default:
      return state;
  }
};

export const Creators = {
  login: payload => ({
    type: Types.LOGIN,
    payload,
  }),
  loginSuccess: payload => ({
    type: Types.LOGIN_SUCCESS,
    payload,
  }),
  loginError: () => ({
    type: Types.LOGIN_ERROR,
  }),
  loginReset: () => ({
    type: Types.LOGIN_RESET,
  }),
  setInitUrl: payload => ({
    type: Types.INIT_URL,
    payload,
  }),
  logout: () => ({
    type: Types.CLEAN_AUTH_USER,
  }),
};

import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects'
import {
   Types as SubscriptionTypes,
   Creators as SubscriptionActions,
} from 'store/ducks/Subscription'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getSubscriptionOverview({ payload }) {
   try {
      const { data, status } = yield call(
         api.get,
         '/subscription/overview',
         payload,
      )
      if (status === 200) {
         yield put(SubscriptionActions.getSubscriptionOverviewSuccess(data))
      } else {
         parseError(data || 'Falha ao listar dados de assinatura.')
         yield put(SubscriptionActions.getSubscriptionOverviewFail(data))
      }
   } catch (err) {
      parseError(err || 'Falha ao listar dados de assinatura.')
      yield put(SubscriptionActions.getSubscriptionOverviewFail())
   }
}

function* getSubscriptionWatcher() {
   yield takeLatest(
      SubscriptionTypes.GET_SUBSCRIPTION_OVERVIEW_REQUEST,
      getSubscriptionOverview,
   )
}

export default function* rootSaga() {
   yield all([fork(getSubscriptionWatcher)])
}

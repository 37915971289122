import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes'
import { toggleCollapsedNav } from 'store/ducks/Setting'
import LogoAiron from 'assets/images/logo-airon-branco.png'
import { Description as DescriptionIcon } from '@material-ui/icons'
import { Button } from '@material-ui/core'

const Index = () => {
  const dispatch = useDispatch()
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings)

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed
    dispatch(toggleCollapsedNav(val))
  }

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'd-block'
    : 'd-none'

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <div className="d-flex flex-grow-1 justify-content-sm-between justify-content-end align-items-center ">
          <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            <img src={LogoAiron} alt="Airon Software" title="Airon Software" />
          </Link>
          <Button
            className="mx-1 my-2"
            onClick={() => {
              window.open(
                'https://aironsoftware.notion.site/wiki-0a850b08b7fa42678fcc2fb58d9e007f',
                '_blank',
                'noreferrer',
              )
            }}
            style={{ color: 'white', 'text-transform': 'none' }}
          >
            <DescriptionIcon className="mx-1" />
            Docs
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Index)

export const Types = {
  LIST_CUSTOMERS_REQUEST: 'LIST_CUSTOMERS_REQUEST',
  LIST_CUSTOMERS_SUCCESS: 'LIST_CUSTOMERS_SUCCESS',
  LIST_CUSTOMERS_ERROR: 'LIST_CUSTOMERS_ERROR',

  GET_CUSTOMER_ID_REQUEST: 'GET_CUSTOMER_ID_REQUEST',
  GET_CUSTOMER_ID_SUCCESS: 'GET_CUSTOMER_ID_SUCCESS',
  GET_CUSTOMER_ID_ERROR: 'GET_CUSTOMER_ID_ERROR',
  GET_CUSTOMER_ID_CLEAN: 'GET_CUSTOMER_ID_CLEAN',

  CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR: 'CREATE_CUSTOMER_ERROR',
  CREATE_RESET: 'CREATE_RESET',

  EDIT_CUSTOMER_REQUEST: 'EDIT_CUSTOMER_REQUEST',
  EDIT_CUSTOMER_SUCCESS: 'EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_ERROR: 'EDIT_CUSTOMER_ERROR',
  EDIT_RESET: 'EDIT_RESET',

  DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_ERROR: 'DELETE_CUSTOMER_ERROR',
  DELETE_CUSTOMER_CLEAN: 'DELETE_CUSTOMER_CLEAN',

  FORCE_READ_CUSTOMER_REQUEST: 'FORCE_READ_CUSTOMER_REQUEST',
  FORCE_READ_CUSTOMER_SUCCESS: 'FORCE_READ_CUSTOMER_SUCCESS',
  FORCE_READ_CUSTOMER_FAIL: 'FORCE_READ_CUSTOMER_FAIL',
}

const INIT_STATE = {
  data: [],
  loading: false,
  success: false,
  error: false,

  createData: {},
  createLoading: false,
  createSuccess: false,
  createError: false,

  dataId: [],
  loadingId: false,
  successId: false,
  errorId: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  editSuccess: false,

  forceReadLoading: false,
  forceReadSuccess: false,
  forceReadError: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createError: false,
      }
    case Types.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createData: action.payload,
        createLoading: false,
        createSuccess: true,
        createError: false,
      }
    case Types.CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        createLoading: false,
        createSuccess: false,
        createError: true,
      }
    case Types.CREATE_RESET:
      return {
        ...state,
        createData: {},
        createSuccess: false,
      }

    case Types.LIST_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    case Types.LIST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
      }
    case Types.LIST_CUSTOMERS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      }

    case Types.GET_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        loadingId: true,
        successId: false,
        errorIdId: false,
      }
    case Types.GET_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        dataId: action.payload,
        loadingId: false,
        successId: true,
        errorId: false,
      }
    case Types.GET_CUSTOMER_ID_ERROR:
      return {
        ...state,
        loadingId: false,
        successId: false,
        errorId: true,
      }
    case Types.GET_CUSTOMER_ID_CLEAN:
      return {
        ...state,
        dataId: null,
        loadingId: false,
        successId: false,
        errorId: false,
      }

    case Types.EDIT_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        editSuccess: false,
        error: false,
      }
    case Types.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        editSuccess: true,
        error: false,
      }
    case Types.EDIT_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        editSuccess: false,
        error: true,
      }
    case Types.EDIT_RESET:
      return {
        ...state,
        editSuccess: false,
      }

    case Types.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      }
    case Types.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      }
    case Types.DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
      }
    case Types.DELETE_CUSTOMER_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
      }

    case Types.FORCE_READ_CUSTOMER_REQUEST:
      return {
        ...state,
        forceReadLoading: true,
        forceReadSuccess: false,
        forceReadError: false,
      }
    case Types.FORCE_READ_CUSTOMER_SUCCESS:
      return {
        ...state,
        forceReadLoading: false,
        forceReadSuccess: true,
        forceReadError: false,
      }
    case Types.FORCE_READ_CUSTOMER_FAIL:
      return {
        ...state,
        forceReadLoading: false,
        forceReadSuccess: false,
        forceReadError: true,
      }

    default:
      return state
  }
}

export const Creators = {
  // POST
  createCustomerRequest: payload => ({
    type: Types.CREATE_CUSTOMER_REQUEST,
    payload,
  }),
  createCustomerSuccess: payload => ({
    type: Types.CREATE_CUSTOMER_SUCCESS,
    payload,
  }),
  createCustomerError: () => ({
    type: Types.CREATE_CUSTOMER_ERROR,
  }),
  createReset: () => ({
    type: Types.CREATE_RESET,
  }),

  // LIST
  listCustomerRequest: filters => ({
    type: Types.LIST_CUSTOMERS_REQUEST,
    payload: filters,
  }),
  listCustomerSuccess: payload => ({
    type: Types.LIST_CUSTOMERS_SUCCESS,
    payload,
  }),
  listCustomerError: () => ({
    type: Types.LIST_CUSTOMERS_ERROR,
  }),

  // SHOW
  getCustomerIdRequest: id => ({
    type: Types.GET_CUSTOMER_ID_REQUEST,
    payload: id,
  }),
  getCustomerIdSuccess: payload => ({
    type: Types.GET_CUSTOMER_ID_SUCCESS,
    payload,
  }),
  getCustomerIdError: () => ({
    type: Types.GET_CUSTOMER_ID_ERROR,
  }),
  getCustomerIdClean: () => ({
    type: Types.GET_CUSTOMER_ID_CLEAN,
  }),

  // UPDATE
  editCustomerRequest: payload => ({
    type: Types.EDIT_CUSTOMER_REQUEST,
    payload,
  }),
  editCustomerSuccess: () => ({
    type: Types.EDIT_CUSTOMER_SUCCESS,
  }),
  editCustomerError: () => ({
    type: Types.EDIT_CUSTOMER_ERROR,
  }),
  editReset: () => ({
    type: Types.EDIT_RESET,
  }),

  // DELETE
  deleteCustomerRequest: id => ({
    type: Types.DELETE_CUSTOMER_REQUEST,
    payload: id,
  }),
  deleteCustomerSuccess: () => ({
    type: Types.DELETE_CUSTOMER_SUCCESS,
  }),
  deleteCustomerError: () => ({
    type: Types.DELETE_CUSTOMER_ERROR,
  }),
  deleteCustomerClean: () => ({
    type: Types.DELETE_CUSTOMER_CLEAN,
  }),

  forceReadCustomerRequest: id => ({
    type: Types.FORCE_READ_CUSTOMER_REQUEST,
    payload: id,
  }),
  forceReadCustomerSuccess: () => ({
    type: Types.FORCE_READ_CUSTOMER_SUCCESS,
  }),
  forceReadCustomerError: () => ({
    type: Types.FORCE_READ_CUSTOMER_FAIL,
  }),
}

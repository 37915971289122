export const Types = {
  GET_VARIABLES_REQUEST: 'GET_VARIABLES_REQUEST',
  GET_VARIABLES_SUCCESS: 'GET_VARIABLES_SUCCESS',
  GET_VARIABLES_FAIL: 'GET_VARIABLES_FAIL',

  POST_VARIABLE_REQUEST: 'POST_VARIABLE_REQUEST',
  POST_VARIABLE_SUCCESS: 'POST_VARIABLE_SUCCESS',
  POST_VARIABLE_FAIL: 'POST_VARIABLE_FAIL',
  POST_VARIABLE_CLEAN: 'POST_VARIABLE_CLEAN',

  SHOW_VARIABLE_REQUEST: 'SHOW_VARIABLE_REQUEST',
  SHOW_VARIABLE_SUCCESS: 'SHOW_VARIABLE_SUCCESS',
  SHOW_VARIABLE_FAIL: 'SHOW_VARIABLE_FAIL',
  SHOW_VARIABLE_CLEAN: 'SHOW_VARIABLE_CLEAN',

  UPDATE_VARIABLE_REQUEST: 'UPDATE_VARIABLE_REQUEST',
  UPDATE_VARIABLE_SUCCESS: 'UPDATE_VARIABLE_SUCCESS',
  UPDATE_VARIABLE_FAIL: 'UPDATE_VARIABLE_FAIL',
  UPDATE_VARIABLE_CLEAN: 'UPDATE_VARIABLE_CLEAN',

  DELETE_VARIABLE_REQUEST: 'DELETE_VARIABLE_REQUEST',
  DELETE_VARIABLE_SUCCESS: 'DELETE_VARIABLE_SUCCESS',
  DELETE_VARIABLE_FAIL: 'DELETE_VARIABLE_FAIL',
  DELETE_VARIABLE_CLEAN: 'DELETE_VARIABLE_CLEAN',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  showLoading: false,
  showError: false,
  showData: null,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,
};

export default function Variable(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_VARIABLES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_VARIABLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.GET_VARIABLES_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.POST_VARIABLE_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      };
    case Types.POST_VARIABLE_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      };
    case Types.POST_VARIABLE_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      };
    case Types.POST_VARIABLE_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      };

    case Types.SHOW_VARIABLE_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      };
    case Types.SHOW_VARIABLE_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      };
    case Types.SHOW_VARIABLE_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      };
    case Types.SHOW_VARIABLE_CLEAN:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: null,
      };

    case Types.UPDATE_VARIABLE_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_VARIABLE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_VARIABLE_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_VARIABLE_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    case Types.DELETE_VARIABLE_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_VARIABLE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_VARIABLE_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_VARIABLE_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getVariablesRequest: payload => ({
    type: Types.GET_VARIABLES_REQUEST,
    payload,
  }),
  getVariablesSuccess: payload => ({
    type: Types.GET_VARIABLES_SUCCESS,
    payload,
  }),
  getVariablesFail: () => ({
    type: Types.GET_VARIABLES_FAIL,
  }),

  // POST
  postVariableRequest: payload => ({
    type: Types.POST_VARIABLE_REQUEST,
    payload,
  }),
  postVariableSuccess: payload => ({
    type: Types.POST_VARIABLE_SUCCESS,
    payload,
  }),
  postVariableFail: () => ({
    type: Types.POST_VARIABLE_FAIL,
  }),
  postVariableClean: () => ({
    type: Types.POST_VARIABLE_CLEAN,
  }),

  // SHOW
  showVariableRequest: payload => ({
    type: Types.SHOW_VARIABLE_REQUEST,
    payload,
  }),
  showVariableSuccess: payload => ({
    type: Types.SHOW_VARIABLE_SUCCESS,
    payload,
  }),
  showVariableFail: () => ({
    type: Types.SHOW_VARIABLE_FAIL,
  }),
  showVariableClean: () => ({
    type: Types.SHOW_VARIABLE_CLEAN,
  }),

  // UPDATE
  updateVariableRequest: payload => ({
    type: Types.UPDATE_VARIABLE_REQUEST,
    payload,
  }),
  updateVariableSuccess: payload => ({
    type: Types.UPDATE_VARIABLE_SUCCESS,
    payload,
  }),
  updateVariableFail: () => ({
    type: Types.UPDATE_VARIABLE_FAIL,
  }),
  updateVariableClean: () => ({
    type: Types.UPDATE_VARIABLE_CLEAN,
  }),

  // DELETE
  deleteVariableRequest: payload => ({
    type: Types.DELETE_VARIABLE_REQUEST,
    payload,
  }),
  deleteVariableSuccess: payload => ({
    type: Types.DELETE_VARIABLE_SUCCESS,
    payload,
  }),
  deleteVariableFail: () => ({
    type: Types.DELETE_VARIABLE_FAIL,
  }),
  deleteVariableClean: () => ({
    type: Types.DELETE_VARIABLE_CLEAN,
  }),
};

import React from 'react'
import { NavLink } from 'react-router-dom'
import { List, ListItem, ListItemText } from '@material-ui/core'

import IntlMessages from '../../util/IntlMessages'

const NavMenuItem = props => {
  const { name, icon, link, fromCollapse } = props

  const ListItemLink = props => <NavLink to={link} {...props} />

  return (
    <List component="div" className="nav-menu-item">
      <ListItem
        button
        className={`prepend-icon nav-menu-link ${
          fromCollapse ? 'nav-menu-collapse' : ''
        }`}
        component={ListItemLink}
      >
        <>{icon && icon}</>
        <ListItemText
          className="nav-text"
          primary={<IntlMessages id={name} />}
        />
      </ListItem>
    </List>
  )
}

export default NavMenuItem

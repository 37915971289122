import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  Creators as CustomerActions,
  Types as CustomerTypes,
} from 'store/ducks/Customer'
import Api from 'util/Api'
import { parseError } from 'util/index'

function* createCustomerSagas({ payload }) {
  try {
    const { status, data } = yield call(Api.post, '/customers', payload)
    if (status === 200) {
      NotificationManager.success('Site cadastrado com sucesso.')
      yield put(CustomerActions.createCustomerSuccess(data))
    } else {
      parseError(data || 'Falha ao cadastrar site.')
      yield put(CustomerActions.createCustomerError())
    }
  } catch (err) {
    parseError(err || 'Falha ao cadastrar site.')
    yield put(CustomerActions.createCustomerError())
  }
}

function* listCustomerSagas({ payload }) {
  try {
    const { status, data } = yield call(Api.get, '/customers', payload)
    if (status === 200) {
      yield put(CustomerActions.listCustomerSuccess(data))
    } else {
      parseError(data || 'Falha ao listar clientes.')
      yield put(CustomerActions.listCustomerError())
    }
  } catch (err) {
    parseError(err || 'Falha ao listar clientes.')
    yield put(CustomerActions.listCustomerError())
  }
}

function* getCustomerIdSagas({ payload }) {
  try {
    const { status, data } = yield call(
      Api.get,
      `/customers/${payload}?with=userNotifications`,
    )
    if (status === 200) {
      yield put(CustomerActions.getCustomerIdSuccess(data))
    } else {
      parseError(data || 'Falha ao carregar cliente.')
      yield put(CustomerActions.getCustomerIdError())
    }
  } catch (err) {
    parseError(err || 'Falha ao carregar cliente.')
    yield put(CustomerActions.getCustomerIdError())
  }
}

function* editCustomerSagas({ payload }) {
  const { id, params } = payload
  try {
    const { status, data } = yield call(Api.put, `/customers/${id}`, params)
    if (status === 200) {
      NotificationManager.success('Site editado com sucesso.')
      yield put(CustomerActions.editCustomerSuccess(data))
    } else {
      parseError(data || 'Erro ao editar site.')
      yield put(CustomerActions.editCustomerError())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar site.')
    yield put(CustomerActions.editCustomerError())
  }
}

function* deleteCustomerSagas({ payload }) {
  try {
    const { data: customers } = yield select(state => state.customer)
    const { status } = yield call(Api.delete, `/customers/${payload}`)
    if (status === 200 || status === 204) {
      const index = customers?.findIndex(item => item.id === payload)
      if (index !== -1) {
        customers.splice(index, 1)
      }

      yield put(CustomerActions.listCustomerSuccess(customers))
      yield put(CustomerActions.deleteCustomerSuccess())
      NotificationManager.success('Site deletado com sucesso.')
    } else {
      parseError(customers || 'Erro ao deletar Site.')
      yield put(CustomerActions.deleteCustomerError())
    }
  } catch (err) {
    parseError(err || 'Erro ao deletar Site.')
    yield put(CustomerActions.deleteCustomerError())
  }
}

function* forceReadCustomerSagas({ payload }) {
  try {
    const { status, data } = yield call(
      Api.get,
      `/customers/${payload}/force-read`,
    )
    if (status === 200) {
      yield put(CustomerActions.forceReadCustomerSuccess())
      NotificationManager.success('Forçamento de leitura iniciado, aguarde...')
    } else {
      parseError(data || 'Erro ao forçar leitura.')
      yield put(CustomerActions.forceReadCustomerError())
    }
  } catch (err) {
    parseError(err || 'Erro ao forçar leitura.')
    yield put(CustomerActions.forceReadCustomerError())
  }
}

function* watchCreateCustomer() {
  yield takeLatest(CustomerTypes.CREATE_CUSTOMER_REQUEST, createCustomerSagas)
}

function* watchListCustomer() {
  yield takeLatest(CustomerTypes.LIST_CUSTOMERS_REQUEST, listCustomerSagas)
}

function* watchGetCustomerId() {
  yield takeLatest(CustomerTypes.GET_CUSTOMER_ID_REQUEST, getCustomerIdSagas)
}

function* watchEditCustomer() {
  yield takeLatest(CustomerTypes.EDIT_CUSTOMER_REQUEST, editCustomerSagas)
}

function* watchDeleteCustomer() {
  yield takeLatest(CustomerTypes.DELETE_CUSTOMER_REQUEST, deleteCustomerSagas)
}

function* forceReadCustomerWatcher() {
  yield takeLatest(
    CustomerTypes.FORCE_READ_CUSTOMER_REQUEST,
    forceReadCustomerSagas,
  )
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateCustomer),
    fork(watchListCustomer),
    fork(watchEditCustomer),
    fork(watchDeleteCustomer),
    fork(watchGetCustomerId),
    fork(forceReadCustomerWatcher),
  ])
}
